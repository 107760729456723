import React, { useLayoutEffect } from 'react';
import Footer from './Footer';
import Logo from "../images/logo-no-background.png";
import Head from "../images/WilliamGrubeHeadshot.JPG";
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons'
import Nav from './Nav'

const About = () => {
    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const openNavOptions = () => {
        document.getElementById("barsBtn").style.display = "none"
        document.getElementById("navPopup").style.display = "flex"
    }

    const closeNavOptions = () => {
        document.getElementById("barsBtn").style.display = "flex"
        document.getElementById("navPopup").style.display = "none"
    }

    const scrollTo = (string, num) => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById(string);
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        num == 1 ? closeNavOptions() : void (0)
    }

    return (
        <body>
            <Helmet>
                <meta charSet="utf-8" />
                <title>About | Gruvy Education</title>
                <link href="/about" />
                <meta name="keywords" content="William Grube, Gruvy Education, Gruvy, Education, AI, Artificial Intelligence, Training, About, Mission" />
                <meta name="description" content="About Gruvy Education, meet the founder William Grube, helping educators prepare there students in an AI-driven world." />

                <script type="application/ld+json">
                    {`
            {
                "@context": "http://schema.org",
                "@type": "Person",
                "name": "William Grube",
                "jobTitle": "Founder",
                "affiliation": "Gruvy Education",
                "sameAs": [
                    "https://twitter.com/GrubeWilliam",
                    "https://www.linkedin.com/in/william-grube-16200a177/",
                    "https://twitter.com/Gruvy_edu"
                ]
            }
        `}
                </script>
            </Helmet>

            <div className="holder">
                <Nav />
                <header className="shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <section className="section-one bg-ghost-white">
                    <div className="title">Introducing the Founder</div>
                    <div className="card">
                        <div className="img-avatar">
                            <img viewBox="0 0 100 100" src={Head} />
                        </div>
                        <div className="card-text">
                            <div className="portada">
                            </div>
                            <div className="title-total">
                                <div className="title">William Grube</div>
                                <h2>About William</h2>
                                <h2 className='aboutSubtitle'>Founder, Gruvy Education</h2>
                                <div className="desc">William Grube is the founder of Gruvy Education. He has worked with over 100 schools across the country. His work has ranged from providing AI training to giving keynotes. William graduated with his degree in Computer Science in the spring of 2024.
                                    <br />
                                    <br />
                                    His efforts are aimed at equipping educators with the tools to prepare students for a world where AI tools are better than what we see today. William hopes to help make learning more relevant in the 21st century.
                                    <br />
                                    <br />
                                    Driven by a strong desire to contribute to the fields of education and artificial intelligence, William applies his knowledge and skills to make a notable impact.
                                    </div>
                                
                                <div className="actions">
                                    <button className='aboutBtn'><i className="far fa-heart"></i></button>
                                    <button className='aboutBtn'><i className="far fa-envelope"></i></button>
                                    <button className='aboutBtn'><i className="fas fa-user-friends"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="title">Mission</div>
                    <div className="card">
                        <div className="img-avatar">
                            <img viewBox="0 0 100 100" className="aboutLogo" src={Logo} />
                        </div>
                        <div className="card-text">
                            <div className="portada portada2">
                            </div>
                            <div className="title-total">
                                <div className="title">The Mission</div>
                                <div className="desc">These are unique times of rapid AI advancement. Our mission at Gruvy Education is to use this big change to bring education into the 21st century. We provide forward-thinking educational solutions to make learning more relevant in the 21st century. This is to ensure our students are ready for the advancements the next decade will bring.
                                </div>
                                <button className='aboutBtn'><i className="far fa-heart"></i></button>
                                <button className='aboutBtn'><i className="far fa-envelope"></i></button>
                                <button className='aboutBtn'><i className="fas fa-user-friends"></i></button>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-four">
                    <div className="container section-four-content">
                        <div className="info text-center">
                            <div className="section-title">
                                <h2 className='text-center white'>Adapt to the times. </h2>
                            </div>
                            <p className="text text-center wider ">Like many other schools have, enhance your knowledge of AI through our presentations and training programs. Schedule a 15 minute meeting with us today and let us show you how our expertise can help you stay ahead of the curve in this rapidly evolving field.</p>
                        </div>
                    </div>
                    <a target="_blank" href="https://calendly.com/williamgrube/gruvy-education-meeting" className="btn get-app-btn">Schedule Meeting</a>
                </section>
                <Footer />
            </div>

        </body>
    );
}

export default About;
