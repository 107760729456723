import { useState, useEffect } from 'react'
import React, { useLayoutEffect } from 'react';
import Footer from './Footer';

import emailjs from "emailjs-com"
import { Helmet } from 'react-helmet';
import { saveAs } from 'file-saver';
import htmlDocx from 'html-docx-js/dist/html-docx';
import Logo from "../images/logo-no-background.png" 

import Nav from './Nav'
import db from '../Firebase'
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import ReactStars from "react-rating-stars-component";
import { Link } from 'react-router-dom';
import { ScrollLink } from 'react-scroll';

const fetch = require('node-fetch');
const StudentSelfAssessmentTemplateGenerator = () => {
    const [userName, setUserName] = useState('')
    const [users, setUsers] = useState([])
    const [userEmail, setUserEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [grade, setGrade] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [countDown, setCountDown] = useState(120);
    const [templateResponseArray, setTemplateResponseArray] = useState(null)
    const [templateResponse, setTemplateResponse] = useState(null)
    const [dots, setDots] = useState('')
    const [docDetails, setDocDetails] = useState(null)
    const [step, setStep] = useState('step1')
    const [errorOccured, setErrorOccured] = useState(false)
    const [isCourse, setIsCourse] = useState(false)
    const usersRef = collection(db, "AI Tools Users")
    const errorRef = collection(db, "AI Tool Error")
    const toolDoc = doc(db, 'AI Tools', 'yo31y7V820xwMvaWxILX')

    const onStarClick = async (newRating) => {
        const ratingsRef = collection(db, 'Ratings');
        await addDoc(ratingsRef, {
            Rating: newRating,
            Name: userName,
            Email: userEmail,
            AIToolName: docDetails.Name, // replace with your tool's name
            Response: templateResponseArray,
        });
    }

    useEffect(() => {
        const fetchToolData = async () => {
            const toolDocSnapshot = await getDoc(toolDoc);
            if (toolDocSnapshot.exists()) {
                // the document exists
                const toolData = toolDocSnapshot.data()
                setDocDetails(toolData)
            } else {
                // the document does not exist
                console.log("No such document!");
            }
        }
        fetchToolData()
    }, [])



    useEffect(() => {
        const getusers = async () => {
            const data = await getDocs(usersRef)
            setUsers(data.docs.map((doc) => ({
                ...doc.data(), id: doc.id
            })))
        }
        getusers()
    }, [])

    const resetForm = () => {
        // Reset each field state here
        setUserName("");
        setUserEmail("");
        setSubject("");
        setGrade("");
        setProjectDescription('')
        setProjectName('')
        setCountDown(120)
        // Also reset the form step
        setStep("step1");
        window.location.reload();
    };


    async function downloadAsWord(e) {
        e.preventDefault();
        let htmlContent = templateResponseArray.map((para) => {
            return `<p className="activityTxt white">${para}<br /></p>`;
        }).join('');

        // Create a Blob object from HTML content
        const converted = htmlDocx.asBlob(`<html><body>${htmlContent}</body></html>`);

        // Trigger download
        saveAs(converted, 'document.docx');
    };

    const prompt1 = `Create a comprehensive and user-friendly student self-assessment template that encourages students to reflect on their learning progress, strengths, and areas for improvement in ` + subject + ` for ` + grade + ` level. This template should be adaptable, promoting self-awareness and personal growth. Consider incorporating the following components:

    Student Information: Include space for the student to provide their name, class, and the date of the self-assessment.
    
    Learning Objectives: List the specific learning objectives for the relevant unit or course, allowing students to assess their understanding and mastery of each objective.
    
    Knowledge and Skills: Provide a set of statements or questions related to the subject matter, focusing on key concepts, skills, or techniques. Encourage students to rate their level of confidence or proficiency in each area using a consistent scale (e.g., 1-5, with 1 being poor and 5 being excellent).
    
    Strengths: Ask students to identify and describe their strengths in the subject or unit, highlighting any areas where they feel particularly confident or have shown significant improvement.
    
    Areas for Improvement: Encourage students to recognize and describe areas where they could improve, including any challenges or difficulties they have encountered.
    
    Goal Setting: Provide space for students to set realistic and achievable goals related to their learning, along with specific action steps to help them reach those goals.
    
    Self-Reflection: Include open-ended questions that prompt students to reflect on their learning experience, personal growth, and the strategies they have used to overcome challenges.
    
    Feedback and Support: Invite students to share any feedback on the course or teaching methods, as well as any additional support or resources they may need to enhance their learning experience.
    
    Teacher Comments (optional): Include a section for the teacher to provide personalized feedback, suggestions for improvement, or recognition of the student's achievements and progress.
    
    Remember to maintain a clear and concise structure, use age-appropriate language, and provide detailed instructions to help students effectively engage with the self-assessment process.`;

    const prompt2 = `Design a comprehensive, student-friendly self-assessment template focused on a specific assignment or project. This tool aims to foster self-awareness and personal growth in students while simultaneously giving them an opportunity to evaluate their understanding of a project with the follwing name: ` + projectName + `. The project has the following description: ` + projectDescription + `. This is for a ` + grade + ` ` + subject + ` classroom.

    Make sure to incorporate the following components in an organized, logical manner:
    
    Student Details: Incorporate a section for students to fill out their name, class, and the date of the assessment. 

    Learning Goals: Enumerate the learning goals related to the `+ projectName + ` project. This will allow students to evaluate their understanding and proficiency of each goal.

    Subject Proficiency: Construct a list of prompts related to the `+ subject + ` knowledge and skills required for the ` + projectName + ` project. Guide students to assess their level of proficiency on a scale, for example, 1-5, where 1 signifies a lack of understanding, and 5 signifies excellent grasp.

    Strengths: Include a section where students can identify and elaborate on their strengths related to the `+ projectName + ` project, showcasing areas where they feel confident or have shown considerable improvement.

    Areas of Improvement: Motivate students to pinpoint and describe areas they need to work on, highlighting challenges or difficulties they have faced during the `+ projectName + ` project.

    Goal Planning: Create a space for students to set realistic learning goals, including specific strategies or steps they will take to achieve these objectives.

    Reflective Analysis: Add open-ended prompts that enable students to introspect on their learning journey, their personal growth, and their strategies to overcome hurdles.

    Course Feedback: Encourage students to give constructive feedback on the `+ subject + ` course, teaching techniques, and specify any additional resources they might require to enhance their learning.

    Teacher's Notes (optional): Include a section for educators to give personalized feedback, offer suggestions for improvement, or acknowledge the student's accomplishments.`;


    useEffect(() => {
        let intervalId;
        // Start the countdown when isLoading is true
        if (isLoading && countDown > 0) {
            intervalId = setInterval(() => {
                setCountDown(countDown => countDown - 1);
            }, 1000);
            dots == '' ? setDots('.') : void (0)
            dots == '.' ? setDots('..') : void (0)
            dots == '..' ? setDots('...') : void (0)
            dots == '...' ? setDots('....') : void (0)
            dots == '....' ? setDots('') : void (0)
        } else if (!isLoading && countDown !== 120 && step == 'step1') {
            // Reset the countdown when isLoading is false
            setCountDown(120);
        } else if (isLoading && countDown === 0) {
            // If isLoading is true and countDown reaches 0, stop the loading
            setIsLoading(false);
        }

        // Clean up function
        return () => clearInterval(intervalId);
    }, [isLoading, countDown]);

    const handleFormSubmit = async e => {
        e.preventDefault();
        const userEmailWithId = users.map(user => ({ id: user.id, email: user.Email })); // Create an array of objects with id and email
        const foundUser = userEmailWithId.find(user => user.email === userEmail);
const now = new Date();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(now.getDate()).padStart(2, '0');
        const year = now.getFullYear();
        const dateLastUsed = `${month}/${day}/${year}`; // Find the user object with the matching email
        window.scrollTo(0, 0)
        setIsLoading(true);

        const toolDocSnapshot = await getDoc(toolDoc);
        if (toolDocSnapshot.exists()) {
            const toolData = toolDocSnapshot.data();
            const currentUses = toolData.Uses;
            const updatedUses = currentUses + 1;
            await updateDoc(toolDoc, { Uses: updatedUses });
        }

        if (foundUser) {
            // If the user was found, update the 'TotalUses' field
            const userDocRef = doc(db, 'AI Tools Users', foundUser.id);
            const userDocSnap = await getDoc(userDocRef);
            const userData = userDocSnap.data();
            const currentTotalUses = userData.StudentSelfAssessTemplateUses || 0;
            const updatedTotalUses = currentTotalUses + 1;
            await updateDoc(userDocRef, { StudentSelfAssessTemplateUses: updatedTotalUses });
        } else {
            // If the user was not found, add a new document
            await addDoc(usersRef, {
                Name: userName,
                Email: userEmail,
                StudentSelfAssessTemplateUses: 1
            });
        }

        await getMessages1(); // remove this and replace with your actual request
        setStep('step3');
        setCountDown(120);
    };

    useEffect(() => {
        if (templateResponse) {
            setTemplateResponseArray(templateResponse.content.toString().split(/\n/))
            setIsLoading(false);
        }
    }, [templateResponse])

    const getMessages1 = async () => {
        const prompt = isCourse ? prompt1 : prompt2
        const options = {
            method: "POST",
            body: JSON.stringify({
                message: prompt
            }),
            headers: {
                "Content-Type": "application/json"
            }
        }
        try {
            const response = await fetch("https://us-central1-teachingduringtheriseofai.cloudfunctions.net/app/completion", options)
            // const response = await fetch("http://localhost:3000/completion", options)
            const data = await response.json()
            setTemplateResponse(data.choices[0].message)
        } catch (error) {
            console.error(error)
            setErrorOccured(true)
            const toolDocSnapshot = await getDoc(toolDoc);
            if (toolDocSnapshot.exists()) {
                const currentErrors = docDetails.Errors
                const updatedErrors = currentErrors + 1
                await updateDoc(toolDoc, { Errors: updatedErrors })
            }
            await addDoc(errorRef, {
                Name: userName,
                Email: userEmail,
                Error: error,
                timeStamp: Timestamp.fromDate(new Date()),
            })
        }
    }


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const closeNavOptions = () => {
        document.getElementById("barsBtn").style.display = "flex"
        document.getElementById("navPopup").style.display = "none"
    }

    const scrollTo = (string, num) => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById(string);
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        num == 1 ? closeNavOptions() : void (0)
    }

    const step1buttonClick = (bool) => {
        setIsCourse(bool)
        setStep('step2')
    }

    return (
        <body>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AI Tool: Student Self-Assessment Template Generator for Educators | Gruvy Education</title>
                <link   href="https://gruvyeducation.com/aitools/selfassessment" />
                <meta
                    name="keywords"
                    content="Gruvy Education, AI in Education, AI Tools, Student Self-Assessment, AI for Educators, AI Teaching Resources, Self-Assessment Template Generator"
                />
                <meta
                    name="description"
                    content="Harness the power of AI in education with Gruvy Education's Student Self-Assessment Template Generator. Equip your students with the tools to evaluate their own learning."
                />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="AI Tool: Student Self-Assessment Template Generator for Educators | Gruvy Education" />
                <meta property="og:description" content="Harness the power of AI in education with Gruvy Education's Student Self-Assessment Template Generator. Equip your students with the tools to evaluate their own learning." />
                <meta property="og:url" content="https://gruvyeducation.com/aitools/selfassessment" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="holder">
                <Nav />
                <header className="shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <section className="section-one bg-ghost-white">
                    <div className="title">Student Self-Assessment Template Generator</div>
                    <div className="container section-one-content">
                        <div className="aiToolsFormContainer container">
                            <div className="buttonContainer backButtonContainer">
                                <Link to='/aitools' className="backBtn">⬅ Back to AI Tools</Link>
                            </div>
                            <div className="buttonContainer">
                                <input className="resetButton" type="button" onClick={resetForm} value="Reset" />
                            </div>
                            <div className="margin"></div>
                            <form className="aiToolsForm" onSubmit={handleFormSubmit} id='aiToolsForm'>
                                {!errorOccured && !isLoading && step == 'step1' &&
                                    <div className='aiToolsFormContent'>
                                        <h2 className='text-center facebookTitle white step2txt'>Select the type of student self-assessment template you wish to create by clicking on the corresponding button.</h2>
                                        <button type="button" onClick={() => step1buttonClick(true)} className="aiToolsFormButton1 assessmentButton">
                                            Generate Student Self-Assessment Template for a Course
                                        </button>
                                        <button type="button" onClick={() => step1buttonClick(false)} className="aiToolsFormButton1 assessmentButton">
                                            Generate Student Self-Assessment Template for a Project/Assignment
                                        </button>
                                    </div>}
                                {
                                    !errorOccured && !isLoading && step == 'step2' && isCourse == true &&
                                    <div className='aiToolsFormContent'>
                                        <h2 className='text-center facebookTitle white step2txt moreBottomMargin'>Fill out the form to create a student self-assessment template for your course.</h2>
                                        <label className="aiToolsFormLabel" htmlFor="name">Name:</label><br />
                                        <input className="aiToolsFormInput" placeholder='First Last' required type="text" id="name" name="name" value={userName} onChange={e => setUserName(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="email">Email:</label><br />
                                        <input className="aiToolsFormInput" placeholder='Enter Email' required type="text" id="email" name="email" value={userEmail} onChange={e => setUserEmail(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="subject">Course:</label><br />
                                        <input className="aiToolsFormInput" placeholder='e.g. Math' required type="text" id="subject" name="subject" value={subject} onChange={e => setSubject(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="grade">Grade Level:</label><br />
                                        <select required className="aiToolsFormSelect" id="grade" name="grade" value={grade} onChange={e => setGrade(e.target.value)}>
                                            <option value="Pre-School">Pre-School</option>
                                            <option value="Kindergarden">Kindergarden</option>
                                            <option value="1st-Grade">1st-Grade</option>
                                            <option value="2nd-Grade">2nd-Grade</option>
                                            <option value="3rd-Grade">3rd-Grade</option>
                                            <option value="4th-Grade">4th-Grade</option>
                                            <option value="5th-Grade">5th-Grade</option>
                                            <option value="6th-Grade">6th-Grade</option>
                                            <option value="7th-Grade">7th-Grade</option>
                                            <option value="8th-Grade">8th-Grade</option>
                                            <option value="9th-Grade">9th-Grade</option>
                                            <option value="10th-Grade">10th-Grade</option>
                                            <option value="11th-Grade">11th-Grade</option>
                                            <option value="12th-Grade">12th-Grade</option>
                                            <option value="College Freshman">College Freshman</option>
                                            <option value="College Sophmore">College Sophmore</option>
                                            <option value="College Junior">College Junior</option>
                                            <option value="College Senior">College Senior</option>
                                            <option value="College Masters Course">Masters Course</option>
                                            <option value="College Doctorate Course">Doctorate Course</option>
                                        </select>
                                        <br />

                                        <br />
                                        <input className="aiToolsFormButton" type="submit" value="Generate Template" />
                                    </div>
                                }
                                {
                                    !errorOccured && !isLoading && step == 'step2' && isCourse == false &&
                                    <div className='aiToolsFormContent'>
                                        <h2 className='text-center facebookTitle white step2txt moreBottomMargin'>Fill out the form to create a student self-assessment template for your assignment/project.</h2>

                                        <label className="aiToolsFormLabel" htmlFor="name">Name:</label><br />
                                        <input className="aiToolsFormInput" placeholder='First Last' required type="text" id="name" name="name" value={userName} onChange={e => setUserName(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="email">Email:</label><br />
                                        <input className="aiToolsFormInput" placeholder='Enter Email' required type="text" id="email" name="email" value={userEmail} onChange={e => setUserEmail(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="subject">Subject:</label><br />
                                        <input className="aiToolsFormInput" placeholder='e.g. Math' required type="text" id="subject" name="subject" value={subject} onChange={e => setSubject(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="grade">Grade Level:</label><br />
                                        <select required className="aiToolsFormSelect" id="grade" name="grade" value={grade} onChange={e => setGrade(e.target.value)}>
                                            <option value="Pre-School">Pre-School</option>
                                            <option value="Kindergarden">Kindergarden</option>
                                            <option value="1st-Grade">1st-Grade</option>
                                            <option value="2nd-Grade">2nd-Grade</option>
                                            <option value="3rd-Grade">3rd-Grade</option>
                                            <option value="4th-Grade">4th-Grade</option>
                                            <option value="5th-Grade">5th-Grade</option>
                                            <option value="6th-Grade">6th-Grade</option>
                                            <option value="7th-Grade">7th-Grade</option>
                                            <option value="8th-Grade">8th-Grade</option>
                                            <option value="9th-Grade">9th-Grade</option>
                                            <option value="10th-Grade">10th-Grade</option>
                                            <option value="11th-Grade">11th-Grade</option>
                                            <option value="12th-Grade">12th-Grade</option>
                                            <option value="College Freshman">College Freshman</option>
                                            <option value="College Sophmore">College Sophmore</option>
                                            <option value="College Junior">College Junior</option>
                                            <option value="College Senior">College Senior</option>
                                            <option value="College Masters Course">Masters Course</option>
                                            <option value="College Doctorate Course">Doctorate Course</option>
                                        </select>
                                        <br />

                                        <label className="aiToolsFormLabel" htmlFor="grade">Project or Assignment Name:</label><br />
                                        <input className="aiToolsFormInput" placeholder='Enter Project Name' required type="text" id="projectName" name="projectName" value={projectName} onChange={e => setProjectName(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="grade">Project or Assignment Description:</label><br />
                                        <input className="aiToolsFormInput" placeholder='Enter Project Description' required type="text" id="projectDescription" name="projectDescription" value={projectDescription} onChange={e => setProjectDescription(e.target.value)} /><br />

                                        <br />
                                        <input className="aiToolsFormButton" type="submit" value="Generate Template" />
                                    </div>
                                }
                                {
                                    !errorOccured && !isLoading && step == 'step3' &&
                                    <div className='aiToolsFormContent'>
                                        {
                                            isCourse ?
                                                <h2 className='text-center facebookTitle white step2txt moreBottomMargin'>Student self-assessment template for your {grade.toLowerCase()} {subject.toLowerCase()} classroom:</h2>
                                                :
                                                <h2 className='text-center facebookTitle white step2txt moreBottomMargin'>Student self-assessment template for '{projectName}':</h2>
                                        }

                                        {templateResponseArray.map((para, index) => {
                                            return <p key={index} className="activityTxt white">{para}<br /></p>
                                        })}
                                        <div className="rateContainer">
                                            <label className='rateLabel'>Rate:</label>
                                            <ReactStars
                                                count={5}
                                                onChange={onStarClick}
                                                size={24}
                                                activeColor="#ffd700"
                                                style={{ marginTop: '8px' }}
                                            />
                                        </div>

                                        <button className="aiToolsFormButton" onClick={downloadAsWord}>Download as Word Document</button>
                                        {/* <button className="aiToolsFormButton" onClick={downloadAsPdf}>Download as PDF</button> */}
                                    </div>

                                }
                                {isLoading && <div className='loadingTxt'>Loading{dots} <br /><br /> {countDown} Seconds Left</div>}
                                {errorOccured && <div className='loadingTxt'>Oops! We're experiencing some difficulty :( <br /><br /> We're sorry for the inconvenience and we're looking into it. Please try refreshing the page or come back a little later. Rest assured, our team is actively investigating this issue. If the problem continues, don't hesitate to reach out for assistance.</div>}
                            </form>
                        </div>
                    </div>
                </section>
                <section className="section-two" >
                    <h2 className='pollsTitle' id='about'>Learn more about Gruvy Education</h2>
                    <div className="container section-two-content">
                        <div className="accordion">
                            <Link to="/testimonials" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Testimonials</span>
                                </div>
                            </Link>
                            <Link to="/about" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">About Us</span>
                                </div>
                            </Link>
                            <a target="_blank" href="https://calendly.com/williamgrube/gruvy-education-meeting" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Schedule a Virtual Meeting</span>
                                </div>
                            </a>
                            <ScrollLink to="contact" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Send us a message</span>
                                </div>
                            </ScrollLink>
                        </div>
                        <div className="info text-center">
                            <div className="section-title">
                                <h2>Join our Facebook Group</h2>
                            </div>
                            <p className="text">Join our community of educators staying ahead of the curve with AI.</p>
                            <a target='_blank' href="https://www.facebook.com/groups/630420622459086/" className="btn">Join</a>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </body>
    );
}

export default StudentSelfAssessmentTemplateGenerator;
