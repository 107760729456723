import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import Nav from './NavNDCEL'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Magic from "../images/magic.jpg"
import Library from '../images/library.jpg'
import Explore from '../images/Explore.png'
import TeachAI from '../images/TeachAI.jpg'
import Doc from '../images/doc.png'
import NDCEL from '../images/NDCEL.png'
import HumanCapitol from '../images/HumanCapitol3.png'
import Communications from '../images/Communications3.png'
import BusinessServices from '../images/BusinessServices3.png'
import TeachingLearning from '../images/TeachingLearning3.png'
import Form from '../images/form.png'
import NavNDCELprincipals from './NavNDCELprincipals';

const NDCELprincipals = () => {

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    return (

        <body>
            <div className="holder">
                <NavNDCELprincipals module="home" />
                <header className="shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    {/* <img className='breakout-title-img' src={NDCEL}/> */}
                    <div className="title noMarginBottom breakout-title">Empowering Education through AI</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <ScrollLink activeClass="active" to="rupak-section" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">🤖📚</span> TeachAI</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="william" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Tool">👨‍🏫🔧</span> Account Setup</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="breakout" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">🗨️👥</span> Breakout Sessions</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <section name='rupak-section' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🤖📚 TeachAI</h2>
                            <div className="tool-grid">
                                <a href="https://www.teachai.org/toolkit" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={TeachAI} alt="TeachAI" />
                                    </div>
                                    <h3 className="tool-title">AI Guidance for Schools Toolkit Website | TeachAI</h3>
                                    <p className="tool-text">AI Guidance for Schools Toolkit Website</p>
                                </a>
                            </div>
                        </section>
                        <section id='william' className="course-section course-activities-section">
                            <h2 className="course-sub-title">👨‍🏫🔧 Account Setup</h2>
                            <div className="activities-wrapper">
                                {/* Navigation bar */}
                                <div className="activity-nav-bar">
                                    <a
                                        href={`#1`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        1
                                    </a> 
                                    <a
                                        href={`#2`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        2
                                    </a>
                                </div>
                                <div key="1" id="1" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Setting up you ChatGPT Account
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://docs.google.com/document/d/18FFY3JducMtMyuc9_m_UrR4nVVzQQv8zgbB9o96B7cM/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Doc} alt="Doc" />
                                                    <span className="link-label">Doc</span>
                                                </a>
                                                <a className="course-link-icon" href="https://youtu.be/v1sDxcN-sX4" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={YouTube} alt="Doc" />
                                                    <span className="link-label">Video</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Follow along with the document or video above to create your ChatGPT account.</p>
                                        </div>
                                    </form>
                                </div>


                                <div key="2" id="2" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Setting up your Magic School Account
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://app.magicschool.ai/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Magic} alt="Magic School" />
                                                    <span className="link-label">MagicSchool</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Click on the link in the 'Links Needed for Activity' section above. Then, click on 'Sign in with Google' or 'Create an account'.</p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                        <section name='breakout' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🗨️👥 Breakout Sessions</h2>
                            <div className="tool-grid">
                                <Link to="/ndcelprincipals/humancapitol" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={HumanCapitol} alt="autoClassmate" />
                                    </div>
                                    <h3 className="tool-title">Human Capital - Dr. McNeff</h3>
                                    <p className="tool-text">Evaluation of employees, Documenting employees, Coaching employees.</p>
                                </Link>
                                <Link to="/ndcelprincipals/miscellaneousneeds" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={BusinessServices} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">Miscellaneous Needs - Dr. Gandhi</h3>
                                    <p className="tool-text">Drafting student contracts, Crisis support, Checklist frameworks, Observation scheduling.</p>
                                </Link>
                                <Link to="/ndcelprincipals/teachingandlearning" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={TeachingLearning} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">Teaching and Learning - William Grube</h3>
                                    <p className="tool-text">Leave equipted to bring AI tools to your staff.</p>
                                </Link>
                                <Link to="/ndcelprincipals/communication" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Communications} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Communications - Stephanie Hochhalter</h3>
                                    <p className="tool-text">Emails to parents, Social posts, Applying for grants</p>
                                </Link>
                            </div>
                        </section>
                        <section name='NextSteps' className="course-section course-resources-section">
                            <h2 className="course-sub-title">✅ Next Steps</h2>
                            <div className="tool-grid">
                                <a href="https://youtu.be/kap60trj-K8" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={YouTube} alt="Youtube" />
                                    </div>
                                    <h3 className="tool-title">Learn about Gruvy Ecucation's AI Training Opportunities for K-12 Educators</h3>
                                    <p className="tool-text">Less than 5 minute explanation video.</p>
                                </a>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_2SwagH33-OQEOTcdQJv4Me0_IIiyAPYqF6NyHY0IYYvSTQ/viewform?usp=sf_link" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Form} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Bring Gruvy Education's AI Training to Your Educators</h3>
                                    <p className="tool-text">Join the 40+ schools participating in our AI in Education Training.</p>
                                </a>
                                {/* <a href="https://calendly.com/williamgrube/meet-william-at-ndcel" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Schedule} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Schedule a time to come see me today.</h3>
                                    <p className="tool-text">I will be set up in Hall B.</p>
                                </a> */}
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default NDCELprincipals;
