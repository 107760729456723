import React, { useLayoutEffect, useRef, useCallback } from 'react';
import Logo from "../images/logo-no-background-train.png";
import emailjs from "emailjs-com"
import Library from "../images/NewLibrary.png"
import Pencil from "../images/toolsIcon.png"
import { Link, useNavigate, useParams } from 'react-router-dom';
import db from '../Firebase'
import { useState, useEffect } from 'react';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import { UserAuth } from '../Context/authContext'
import Auto from '../images/auto.jpg'
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import Nav from './TrainNav'
import HubVideo from '../Video/NEW WELCOME.mp4'
import 'react-html5video/dist/styles.css'
import Thumbnail from '../images/IntroVideo.png'
import Footer from './Footer';
import Magic from '../images/magic.jpg'
import Diffit from '../images/diffit.png'
import Eduaide from '../images/eduaide.jpg'
import Gradescope from '../images/Gradescope.jpg'
import MagicSchool from "../images/magic.jpg"
import Elicit from '../images/Elicit.png'
import Semantic from '../images/Semantic.jpg'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import SchoolAI from '../images/SchoolAI.jpg'


const Training = () => {

    const { id } = useParams();
    const [emails, setEmails] = useState([])
    const [emailMap, setEmailMap] = useState(new Map());
    const [userEmail, setUserEmail] = useState("")
    const [signInStep, setSignInStep] = useState("1")
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [emailNotTyped, setemailNotTyped] = useState(false)
    const [shouldCreateAccount, setShouldCreateAccount] = useState(false)
    const [passwordReset, setPasswordReset] = useState(false)
    const [shouldSignIn, setShoundSignIn] = useState(false)
    const schoolDoc = doc(db, 'School', id)
    const emailsRef = collection(schoolDoc, "Emails")
    const sessionDatesRef = collection(schoolDoc, "Session Dates")
    const sessionDatesDoc = doc(sessionDatesRef, "Session Dates")
    const dueDatesRef = collection(schoolDoc, "Due Dates")
    const dueDatesDoc = doc(dueDatesRef, "Due Dates")
    const [password, setPasword] = useState('')
    const [authPassword, setAuthPassword] = useState('')
    const [rePassword, setRePasword] = useState('')
    const [initialRender, setInitialRender] = useState(true)
    const { createUser, signIn, user, logout } = UserAuth()
    const [error, setError] = useState('')
    const [goodPass, setGoodPass] = useState(false)
    const [schoolName, setSchoolName] = useState('')
    const [schoolPic, setSchoolPic] = useState('')
    const [schoolColor, setSchoolColor] = useState('')
    const [schoolMethod, setSchoolMethod] = useState('')
    const [day1date, setDay1date] = useState('')
    const [day1time, setDay1time] = useState('')
    const [day2date, setDay2date] = useState('')
    const [day2time, setDay2time] = useState('')
    const [day3date, setDay3date] = useState('')
    const [day3time, setDay3time] = useState('')
    const [day4date, setDay4date] = useState('')
    const [day4time, setDay4time] = useState('')
    const linkDoc = doc(db, "Master Links", "NoAsync")
    const linksRef1 = collection(linkDoc, "Day1")
    const linksRef2 = collection(linkDoc, "Day2")
    const linksRef3 = collection(linkDoc, "Day3")
    const linksRef4 = collection(linkDoc, "Day4")
    const [links1, setLinks1] = useState([])
    const [links2, setLinks2] = useState([])
    const [links3, setLinks3] = useState([])
    const [links4, setLinks4] = useState([])
    const [day1dueDate, setDay1dueDate] = useState('')
    const [day2dueDate, setDay2dueDate] = useState('')
    const [day3dueDate, setDay3dueDate] = useState('')
    const [day4dueDate, setDay4dueDate] = useState('')
    const [allowVideo, setAllowVideo] = useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false)
    const [userName, setUserName] = useState("")
    const [numberOfDays, setNumberOfDays] = useState(4);
    const [userAllowed, setUserAllowed] = useState(true)
    const [isLoaded, setIsLoaded] = useState(false)
    const [trainingType, setTrainingType] = useState("")
    const [moduleTitles, setModuleTitles] = useState([]);


    const videoLoaded = () => {
        setIsVideoLoaded(true);
    };

    useEffect(() => {
        setAllowVideo(true);
    }, []);



    useEffect(() => {
        const getLinks = async (ref, setter) => {
            const data = await getDocs(ref);
            setter(data.docs.map((doc) => ({
                ...doc.data(), id: doc.id
            })));
        };

        getLinks(linksRef1, setLinks1);
        getLinks(linksRef2, setLinks2);
        getLinks(linksRef3, setLinks3);
        getLinks(linksRef4, setLinks4);
    }, []);

    const navigate = useNavigate()

    // const initialRender = useRef(true)
    // const goodPass = useRef(false)
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;

        return () => {
            mounted.current = false;
        };
    }, []);

    const fetchUserDoc = useCallback(async (userDoc) => {
        const userDocSnap = await getDoc(userDoc);
        if (userDocSnap.exists()) {
            setIsAdmin(userDocSnap.data().Admin);
            setUserName(userDocSnap.data().Name);
        } else {
            console.log('No such document exists!');
        }
    }, []);

    useEffect(() => {
        if (user) {
            if (user.email) {  // Check if user object exists
                const userDoc = doc(emailsRef, user.email);  // emailsRef should be defined elsewhere in your code
                fetchUserDoc(userDoc);
            }
        }

    }, [user, emailsRef, fetchUserDoc]);

    const fetchSchoolDoc = async (id) => {
        try {
            const schoolDocSnap = await getDoc(schoolDoc);
            if (schoolDocSnap.exists()) {
                setSchoolName(schoolDocSnap.data().Name);
                setSchoolPic(schoolDocSnap.data().icon);
                setSchoolColor(schoolDocSnap.data().color);
                setSchoolMethod(schoolDocSnap.data().delivery);
                setTrainingType(schoolDocSnap.data().trainingType);
                const titlesDocRef = doc(db, 'School', id, 'Content', 'Titles');
                const titlesDocSnap = await getDoc(titlesDocRef);
                if (titlesDocSnap.exists()) {
                    setModuleTitles(titlesDocSnap.data().titles);
                }
                setIsLoaded(true);  // Setting it to true after processing
            } else {
                console.log('No such document exists!');
                setIsLoaded(true);  // Optionally set to true even if document doesn't exist
            }
        } catch (error) {
            console.log('An error occurred:', error);
            setIsLoaded(true);  // Optionally set to true even in case of an error
        }
    };


    useEffect(() => {
        if (!schoolName) {
            return
        }
        const fetchData = async () => {
            const docRef = doc(db, 'School', schoolName, 'Content', 'Async');

            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                setNumberOfDays(data.numberOfDays);  // Assuming 'numberOfDays' exists in your document
            } else {
                console.log("No such document!");
            }
        };

        fetchData();
    }, [schoolName]);

    const fetchSessionDatesDoc = async () => {
        const sessionDatesDatesSnap = await getDoc(sessionDatesDoc);
        if (sessionDatesDatesSnap.exists()) {
            setDay1date(sessionDatesDatesSnap.data().One)
            setDay1time(sessionDatesDatesSnap.data().OneTime)
            setDay2date(sessionDatesDatesSnap.data().Two)
            setDay2time(sessionDatesDatesSnap.data().TwoTime)
            setDay3date(sessionDatesDatesSnap.data().Three)
            setDay3time(sessionDatesDatesSnap.data().ThreeTime)
            setDay4date(sessionDatesDatesSnap.data().Four)
            setDay4time(sessionDatesDatesSnap.data().FourTime)
        } else {
            console.log('No such document exists!');
        }
    };

    const fetchDueDatesDoc = async () => {
        const dueDatesSnap = await getDoc(dueDatesDoc);
        if (dueDatesSnap.exists()) {
            setDay1dueDate(dueDatesSnap.data().One)
            setDay2dueDate(dueDatesSnap.data().Two)
            setDay3dueDate(dueDatesSnap.data().Three)
            setDay4dueDate(dueDatesSnap.data().Four)
        } else {
            console.log('No such document exists!');
        }
    };


    useEffect(() => {
        fetchSchoolDoc(id);
        fetchSessionDatesDoc();
        fetchDueDatesDoc();
    }, [id]);

    useEffect(() => {
        const getEmails = async () => {
            const data = await getDocs(emailsRef);
            const newEmailMap = new Map();
            data.docs.forEach(doc => {
                const emailObj = { ...doc.data(), id: doc.id };
                newEmailMap.set(emailObj.Email.toLowerCase(), emailObj);
            });
            setEmailMap(newEmailMap);
        };
        getEmails();
    }, []);


    useEffect(() => {
        if (emailMap.size > 0 && user) {
            const emailExists = emailMap.has(user.email.toLowerCase());
            setUserAllowed(emailExists);
        }
    }, [emailMap, user]);




    function setCookie(name, value, exdays, path) {
        let expires = ""
        if (exdays) {
            let date = new Date()
            date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000)
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (encodeURIComponent(value) || "") + expires + "; path=" + path
    }

    function getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(";")
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) == " ") c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL50UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    const refreshPage = () => {
        window.location.reload()
    }
    function getEmailStatus(emailRef, userEmail) {
        try {
            // Check if emailRef and userEmail are defined
            if (!emailRef || !userEmail) {
                throw new Error('Email reference or user email is missing.');
            }

            const isComplete = emailRef.some(answer => answer.Email.toLowerCase().includes(userEmail.toLowerCase()));

            return (
                <h3 className={`knowledgeTitle ${isComplete ? 'green' : 'red'}`}>
                    {isComplete ? 'Complete' : 'Incomplete'}
                </h3>
            );
        } catch (error) {
            // You can handle the error as you see fit, or simply log it
            console.error(error.message);
            return (
                <h3 className="knowledgeTitle red">
                    Error: Unable to determine status
                </h3>
            );
        }
    }


    const handleSignIn = async (e) => {
        e.preventDefault();

        let emailObj = emailMap.get(userEmail.toLowerCase());  // Using the Map for O(1) lookup
        // Check if the user is for 'oneOff' training and not in emailMap
        if (trainingType === "oneOff" && !emailObj) {
            const newUserDetails = {
                isAccount: true,
                Email: userEmail.toLowerCase(),
                Admin: false
            };

            // Add user to emailsRef with a specific ID
            try {
                const newUserRef = doc(emailsRef, userEmail.toLowerCase());
                await setDoc(newUserRef, newUserDetails);

                // Add user to emailMap
                emailMap.set(userEmail.toLowerCase(), newUserDetails);
                emailObj = newUserDetails; // Update local reference

                // Prompt user to create an account
                setShouldCreateAccount(true);
                setInvalidEmail(false);
                document.getElementById("emailField").disabled = true;
                setSignInStep("2");
            } catch (error) {
                console.error("Error adding document: ", error);
            }
            return;
        }

        if (emailObj) {  // Equivalent to emailFound = true;
            if (signInStep === "1") {
                if (!emailObj.isAccount) {
                    setShouldCreateAccount(true);
                    setInvalidEmail(false);
                    document.getElementById("emailField").disabled = true;  // Try to replace this with React state
                    setSignInStep("2");
                } else {
                    setShoundSignIn(true);
                    setInvalidEmail(false);
                    document.getElementById("emailField").disabled = true;  // Try to replace this with React state
                    setSignInStep("3");
                }
            }

            else if (signInStep === "2") {
                if (!goodPass) {
                    alert("Passwords do not match");
                    return;
                }

                try {
                    // Create user account (for both existing emailObj and oneOff training users)
                    await createUser(userEmail, password, emailObj?.name);
                    await signIn(userEmail, password);
                    if (emailObj) {
                        await updateDoc(doc(emailsRef, emailObj.id), { isAccount: true });
                    }
                } catch (e) {
                    setError(e.message);
                }
            }

            else if (signInStep === "3") {
                try {
                    await signIn(userEmail, authPassword);
                } catch (e) {
                    if (!e.message.includes('wrong-password')) {
                        document.getElementById('emailErrorMessage').style.display = 'flex';  // Try to replace this with React state
                        setTimeout(() => {
                            document.getElementById('emailErrorMessage').style.display = 'none';  // Try to replace this with React state
                        }, 5000);
                    } else {
                        document.getElementById('passErrorMessage').style.display = 'flex';  // Try to replace this with React state
                        setTimeout(() => {
                            document.getElementById('passErrorMessage').style.display = 'none';  // Try to replace this with React state
                        }, 5000);
                    }
                }
            }
        } else {  // If email is not found in the regular process
            setemailNotTyped(false);
            setInvalidEmail(true);
            console.log('Email not found');
        }
    };

    const shouldLetThrough = () => {
        for (var i = 0; i < emails.length; i++) {
            const cookieEmail = emails[i].Email.replace("@", "%40")
            if (cookieEmail == getCookie("user")) {
                return true
            }
        }
        return false
    }

    function openCalendly() {
        window.open("https://calendly.com/williamgrube/ai-in-education-training-help");
        return true;
    }
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useEffect(() => {
        if (initialRender) {
            setInitialRender(false)
        } else {
            if (password.length < 8 && mounted.current == true && shouldCreateAccount) {
                document.getElementById("passDoMatch").style.display = "none";
                document.getElementById('passNoMatch').style.display = 'none'
                document.getElementById('passNoLong').style.display = 'flex'
            } else {
                if (rePassword === password && mounted.current == true && shouldCreateAccount) {
                    document.getElementById('passDoMatch').style.display = 'flex'
                    document.getElementById('passNoMatch').style.display = 'none'
                    document.getElementById('passNoLong').style.display = 'none'
                    setGoodPass(true)
                } else {
                    if (mounted.current == true && shouldCreateAccount) {
                        document.getElementById('passDoMatch').style.display = 'none'
                        document.getElementById('passNoMatch').style.display = 'flex'
                        document.getElementById('passNoLong').style.display = 'none'
                    }
                }
            }

        }

    }, [rePassword, password])

    const handleLogout = async () => {
        try {
            await logout()
            navigate('/training')
        } catch (e) {
            console.log(e.message)
        }
    }

    function formatDate(serializedTimestamp) {
        if (typeof serializedTimestamp === 'string') {
            return serializedTimestamp;
        }

        // Convert Firestore timestamp to JavaScript Date
        const date = new Date(serializedTimestamp.seconds * 1000);

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();

        return `${month} ${day}, ${year}`;
    }

    function formatTime(serializedTimestamp, dueDate) {
        // If serializedTimestamp is the string "Asynchronous", use dueDate with formatDate()
        if (serializedTimestamp === 'Asynchronous') {
            return "Complete by " + formatDate(dueDate); // I assume formatDate() is a function you have or will define
        }

        if (typeof serializedTimestamp === 'string') {
            return serializedTimestamp;
        }

        // Convert Firestore timestamp to JavaScript Date object
        const date = new Date(serializedTimestamp.seconds * 1000);

        // Extract hours and minutes
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes; // prepend 0 if less than 10

        return `${hours}:${minutes} ${ampm}`;
    }

    const handleNeedAccess = () => {
        // Handle the "Need Access?" button click here.
        console.log("Need Access clicked");
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (!userEmail) {
            setemailNotTyped(true);
            setInvalidEmail(false);
            console.log("Please enter an email address");
            // Optionally show a message to the user
            return;
        }

        const emailObj = emailMap.get(userEmail.toLowerCase()); // Using the Map for O(1) lookup

        if (emailObj) { // Equivalent to emailFound = true
            setemailNotTyped(false);
            const auth = getAuth();
            const emailAddress = userEmail;

            sendPasswordResetEmail(auth, emailAddress)
                .then(() => {
                    setPasswordReset(true);
                    console.log("Password reset email sent!");
                    // Optionally show a message to the user
                })
                .catch((error) => {
                    console.log("Error sending password reset email: ", error);
                    // Optionally show an error message to the user
                });
        } else {
            setInvalidEmail(false);
            setemailNotTyped(true);
            console.log("Email not found or invalid");
            // Optionally show an error message to the user
        }
    };


    const backToTraining = () => {
        navigate('/training')
    }

    return (
        <body>
            {!isLoaded ? (
                <div className="loader-wrapper">
                    <div className="loader">
                        <div className="spinner"></div>
                        <div className="loading-text">Loading...</div>
                    </div>
                </div>
            ) : (
                <>
                    {
                        user ? user && !userAllowed ?
                            <div className="auth">
                                <form className="authCard">
                                    <h6 className="authCardWords">Oops! Looks like you pressed on the wrong school.</h6>
                                    <div className="formGroup">
                                        <button onClick={backToTraining} className="auth-btn">Go back.</button>
                                    </div>
                                    <div className="formGroup extraButtons">
                                        <a target='_blank' href="https://docs.google.com/forms/d/e/1FAIpQLSf-bwMgt5fGFJ31yREXMCom7b26m-IvoCNNgQG6cwrQHkvAFQ/viewform?usp=sf_link" className="auth-extra-btn">Need Access?</a>
                                    </div>
                                </form>
                            </div>
                            : void (0)
                            :
                            <div className="auth">
                                <form className="authCard">
                                    <h6 className="authCardWords">To sign in or sign up, please enter your school-issued email address:</h6>
                                    <div className="formGroup">
                                        <label className="auth-label">Email</label>
                                        <input placeholder="Enter Your School Issued Email" id="emailField" type="email" className="inputField" onChange={(event) => setUserEmail(event.target.value)} />
                                    </div>
                                    {shouldCreateAccount &&
                                        <div className="passwordGroup">
                                            <div className="formGroup">
                                                <label className="auth-label">Password</label>
                                                <input placeholder="Create Password" type="password" className="inputField" onChange={(event) => setPasword(event.target.value)} />
                                            </div>
                                            <div className="formGroup">
                                                <label className="auth-label">Retype Password</label>
                                                <input placeholder="Retype Password" type="password" className="inputField" onChange={(event) => setRePasword(event.target.value)} />
                                            </div>
                                            <p id="passNoMatch">Passwords do not match</p>
                                            <p id="passNoLong">Passwords not long enough</p>
                                            <p id="passDoMatch">Passwords match</p>
                                        </div>
                                    }
                                    {shouldSignIn &&
                                        <div className="formGroup">
                                            <label className="auth-label">Password</label>
                                            <input placeholder="Enter Password" type="password" className="inputField" onChange={(event) => setAuthPassword(event.target.value)} />
                                            <p id="passErrorMessage">Wrong Password, Try Again</p>
                                            <p id="emailErrorMessage">Wrong Email, Try Again</p>
                                        </div>
                                    }
                                    {invalidEmail && <p className="invalidEmail">Invalid Email</p>}
                                    {emailNotTyped && <p className="invalidEmail">Please type in your school issued email, then press "Reset Password" again.</p>}
                                    {(invalidEmail) && <p className="invalidEmail">Double-check spelling. If issues persist, select "Need Access?" below.</p>}
                                    {passwordReset && <p className="invalidEmail green">A password reset link has been sent to your email. Please check your inbox to continue.</p>}
                                    <div className="formGroup">
                                        <button onClick={handleSignIn} className="auth-btn">Login</button>
                                    </div>
                                    <div className="formGroup extraButtons">
                                        <a target='_blank' href="https://docs.google.com/forms/d/e/1FAIpQLSf-bwMgt5fGFJ31yREXMCom7b26m-IvoCNNgQG6cwrQHkvAFQ/viewform?usp=sf_link" className="auth-extra-btn">Need Access?</a>
                                        <button onClick={handleResetPassword} className="auth-extra-btn">Reset Password</button>
                                    </div>
                                </form>
                            </div>

                    }
                    <div className="holder" id='top'>
                        <Nav url={id} day='home' moduleTitles={moduleTitles} numberOfDays={numberOfDays} type={trainingType} />
                        <nav className="navbar">
                            {/* <div className="topLinks">
                        <Link to="/training" className="back black">&larr; Exit training</Link>
                        <button onClick={handleLogout} className="back black">Sign Out</button>
                    </div> */}
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className="container nav-content">
                                <div className="brand-and-toggler">
                                    <a href="/training" className="navbar-brand">
                                        {user ? <img src={Logo} className="logo extraMargin train-logo" alt="site logo" /> : void (0)}
                                    </a>
                                </div>
                            </div>
                        </nav>
                        <header className="BBEtrainingHeader" style={{ backgroundImage: `linear-gradient(333deg, #${schoolColor} 20%, #${schoolColor} 80%)` }}>
                            <div className="container header-content noBottomMargin">
                                <div className="header-text text-white text-center">
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <div className="school">{schoolName}</div>
                                    <div className="btn-group">
                                        {isAdmin ?
                                            <button
                                                onClick={() => {
                                                    const url = `/adminProgressReport/${encodeURIComponent(id)}`;
                                                    window.open(url, '_blank');
                                                }}
                                            >
                                                View Staffs Progess Report
                                            </button>
                                            :
                                            <button onClick={() => {
                                                const url = `/ProgressReport/${encodeURIComponent(id)}`;
                                                window.open(url, '_blank');
                                            }} target="_blank" type="button">View Your Progress</button>
                                        }
                                        <button onClick={scrollToBottom} type="button">Contact Me</button>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </header>
                        <section className="section-one bg-ghost-white center">

                            {allowVideo && (
                                <div id='lecture-section'>
                                    <video controlsList="nodownload" controls className="course-video-content" poster={Thumbnail}>
                                        <source src={HubVideo} type="video/mp4" />
                                    </video>
                                </div>
                            )}
                            <div
                                style={{
                                    backgroundImage:
                                        'url("../images/IntroVideo.png")',
                                }}
                                className={`fallback ${isVideoLoaded ? "hide" : "show"}`}
                            />

                            <div className="course-container">
                                <div className="course-navbar">
                                    <ScrollLink to="Hub" smooth={true} duration={50} className="course-nav-item">
                                        <div><span role="img" aria-label="Cap">🎓</span> Training Content</div>
                                    </ScrollLink>
                                    <ScrollLink to="Resources" smooth={true} duration={50} className="course-nav-item">
                                        <div><span role="img" aria-label="Book">📚</span> Resources</div>
                                    </ScrollLink>
                                    <a onClick={() => {
                                        const url = `/ProgressReport/${encodeURIComponent(id)}`;
                                        window.open(url, '_blank');
                                    }} className="course-nav-item cursorPointer">
                                        <div><span role="img" aria-label="Check Mark">✅</span> Completion Summary</div>
                                    </a>
                                    <ScrollLink to="contact" smooth={true} duration={50} className="course-nav-item">
                                        <div><span role="img" aria-label="Book">📱</span> Contact Me</div>
                                    </ScrollLink>
                                    <button onClick={handleLogout} className="course-nav-item">
                                        <div><span role="img" aria-label="Book">🚪</span> Log Out</div>
                                    </button>
                                </div>
                            </div>
                            <h2 id='Hub' className="course-sub-title moreMarginTop">🎓 Training Content</h2>
                            <div className="tool-grid">
                                {/* Dynamic content */}
                                {Array.from({ length: numberOfDays }, (_, i) => `Day${i + 1}`).map((dayItem, index) => (
                                    <a href={`/training/${id}/${dayItem}`} className="tool-card" key={index}>
                                        <div className="tool-icon largeIcon"><span role="img" aria-label="Day">🗓️</span></div>
                                        <h3 className="tool-title">{dayItem.replace('Day', 'Module ')}</h3>
                                    </a>
                                ))}
                            </div>
                            <h2 id='Resources' className="course-sub-title moreMarginTop" >Training Resources</h2>
                            <div className="tool-grid">
                                <a href="https://www.magicschool.ai/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={MagicSchool} alt="eduaide" />
                                    </div>
                                    <h3 className="tool-title">Magic School</h3>
                                    <p className="tool-text">Magic School is an AI tool to help eliminate teacher burnout and make high-quality teaching resources universally accessible.</p>
                                </a>
                                <a href="https://web.diffit.me/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Diffit} alt="diffit" />
                                    </div>
                                    <h3 className="tool-title">Diffit</h3>
                                    <p className="tool-text">Teachers use Diffit to instantly get “just right” resources for any lesson, saving tons of time and helping all students to access grade level content. Diffit has the ability to generate PDFs, Google Docs, and Google Forms.</p>
                                </a>
                                <a href="https://www.eduaide.ai/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Eduaide} alt="auto classmate" />
                                    </div>
                                    <h3 className="tool-title">Eduaide.Ai</h3>
                                    <p className="tool-text">Eduaide.Ai is an AI-driven platform that helps educators create lesson plans, teaching resources, and assessments. Use code "Gruvy" at checkout to get a year of Eduaide Pro for 20% off!</p>
                                </a>
                                <a href="https://schoolai.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={SchoolAI} alt="auto classmate" />
                                    </div>
                                    <h3 className="tool-title">SchoolAI</h3>
                                    <p className="tool-text">The all-in-one AI platform for your classroom. Designed for K12 students with built-in monitoring & controls for teachers.</p>
                                </a>
                                <a href="https://gruvyeducation.com/aitools" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Pencil} alt="ai tools for educators" />
                                    </div>
                                    <h3 className="tool-title">AI Tools for Educators</h3>
                                    <p className="tool-text">Collection of useful AI Tools for Educators.</p>
                                </a>
                                <a href="https://gruvyeducation.com/promptlibrary" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Library} alt="prompt library" />
                                    </div>
                                    <h3 className="tool-title">Prompt Library</h3>
                                    <p className="tool-text">Collection of useful ChatGPT prompts for educators.</p>
                                </a>
                            </div>
                        </section>
                        <Footer />
                    </div >
                </>
            )
            }
        </body >
    );
}

export default Training;
