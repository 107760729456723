import { useState, useEffect } from 'react'
import React, { useLayoutEffect } from 'react';
import Footer from './Footer';

import emailjs from "emailjs-com"
import { Helmet } from 'react-helmet';
import { saveAs } from 'file-saver';
import htmlDocx from 'html-docx-js/dist/html-docx';
import Logo from "../images/logo-no-background.png" 

import Nav from './Nav'
import db from '../Firebase'
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import ReactStars from "react-rating-stars-component";
import { Link } from 'react-router-dom';
import { ScrollLink } from 'react-scroll';

const fetch = require('node-fetch');
const SyllabusGenerator = () => {
    const [userName, setUserName] = useState('')
    const [users, setUsers] = useState([])
    const [userEmail, setUserEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [grade, setGrade] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [courseName, setCourseName] = useState('')
    const [courseInfo, setCourseInfo] = useState('');
    const [instructorInfo, setInstructorInfo] = useState('');
    const [courseDescription, setCourseDescription] = useState('');
    const [learningObjectives, setLearningObjectives] = useState('');
    const [courseMaterials, setCourseMaterials] = useState('');
    const [courseSchedule, setCourseSchedule] = useState('');
    const [grading, setGrading] = useState('');
    const [attendance, setAttendance] = useState('');
    const [integrity, setIntegrity] = useState('');
    const [accessibility, setAccessibility] = useState('');
    const [communication, setCommunication] = useState('');
    const [policy, setPolicy] = useState('');
    const [countDown, setCountDown] = useState(120);
    const [templateResponseArray, setTemplateResponseArray] = useState(null)
    const [templateResponse, setTemplateResponse] = useState(null)
    const [dots, setDots] = useState('')
    const [docDetails, setDocDetails] = useState(null)
    const [step, setStep] = useState('step1')
    const [errorOccured, setErrorOccured] = useState(false)
    const usersRef = collection(db, "AI Tools Users")
    const errorRef = collection(db, "AI Tool Error")
    const toolDoc = doc(db, 'AI Tools', 'Vgag0tqnvrNUy63efY59')

    const onStarClick = async (newRating) => {
        const ratingsRef = collection(db, 'Ratings');
        await addDoc(ratingsRef, {
            Rating: newRating,
            Name: userName,
            Email: userEmail,
            AIToolName: docDetails.Name, // replace with your tool's name
            Response: templateResponseArray,
        });
    }

    useEffect(() => {
        const fetchToolData = async () => {
            const toolDocSnapshot = await getDoc(toolDoc);
            if (toolDocSnapshot.exists()) {
                // the document exists
                const toolData = toolDocSnapshot.data()
                setDocDetails(toolData)
            } else {
                // the document does not exist
                console.log("No such document!");
            }
        }
        fetchToolData()
    }, [])



    useEffect(() => {
        const getusers = async () => {
            const data = await getDocs(usersRef)
            setUsers(data.docs.map((doc) => ({
                ...doc.data(), id: doc.id
            })))
        }
        getusers()
    }, [])

    const resetForm = () => {
        // Reset each field state here
        setCountDown(120)
        // Also reset the form step
        setStep("step1");
        window.location.reload();
    };


    async function downloadAsWord(e) {
        e.preventDefault();
        let htmlContent = templateResponseArray.map((para) => {
            return `<p className="activityTxt white">${para}<br /></p>`;
        }).join('');

        // Create a Blob object from HTML content
        const converted = htmlDocx.asBlob(`<html><body>${htmlContent}</body></html>`);

        // Trigger download
        saveAs(converted, 'document.docx');
    };

    const prompt1 =
        `Create a detailed syllabus for a ` + courseName + `, including the following sections (This must be ready-made to give to students.):

        Course Information: Title, number, credit hours, times, location. Course info - ` + courseInfo + `. END OF COURSE INFO.
        
        Instructor Information: Name, contact details, office hours. Instructor Information info - ` + instructorInfo + `. END OF INSTRUCTOR INFO.
        
        Course Description: Brief overview, purpose, alignment with curriculum. Course Description info - ` + courseDescription + `. END OF COURSE DESCRIPTION.
        
        ` + (learningObjectives == '' ? '' : `Learning Objectives: Specific, measurable objectives. Learning Objectives info - ` + learningObjectives + `. END OF LEARNING OBJECTIVES.`) + `
        
        ` + (courseMaterials == '' ? '' : `Course Materials: Required and recommended resources. Course materials info - ` + courseMaterials + `. END OF COURSE MATERIALS`) + `
        
        ` + (courseSchedule == '' ? '' : `Course Schedule: Topics, assignments, deadlines, exams. Course Schedule info - ` + courseSchedule + `. END OF COURSE SCHEDULE`) + `

        ` + (grading == '' ? '' : `Assessment and Grading: Methods, weightage, scale, policies. Assessments and Grading info - ` + grading + `. END OF GRADING INFO.`) + `
        
        ` + (attendance == '' ? '' : `Attendance and Participation: Expectations, policies for late work or special circumstances. Attendance and Participation info - ` + attendance + `. END OF ATTENDENCE INFO.`) + `
        
        ` + (integrity == '' ? '' : `Academic Integrity: Statement on academic honesty. Academic integrity info - ` + integrity + `. END OF ACADEMIC INTEGRITY INFO.`) + `
        
        ` + (accessibility == '' ? '' : `Accessibility and Accommodations: Services for students with disabilities. Accessibility and Accommodations info - ` + accessibility + `. END OF ACCESSIBILITY INFO.`) + `
        
        ` + (communication == '' ? '' : `Communication and Updates: Methods for course-related announcements. Communication and Updates info - ` + communication + `. END OF COMMUNICATION INFO.`) + `
        
        ` + (policy == `` ? '' : `Course Policies and Expectations: Additional guidelines. Course Policies and Expectations - ` + policy + `. END OF POILY INFO.`) + `
        
        Ensure clarity, concise language, and detailed formatting for student understanding. This must be ready-made to give to students.`;



    useEffect(() => {
        let intervalId;
        // Start the countdown when isLoading is true
        if (isLoading && countDown > 0) {
            intervalId = setInterval(() => {
                setCountDown(countDown => countDown - 1);
            }, 1000);
            dots == '' ? setDots('.') : void (0)
            dots == '.' ? setDots('..') : void (0)
            dots == '..' ? setDots('...') : void (0)
            dots == '...' ? setDots('....') : void (0)
            dots == '....' ? setDots('') : void (0)
        } else if (!isLoading && countDown !== 120 && step == 'step1') {
            // Reset the countdown when isLoading is false
            setCountDown(120);
        } else if (isLoading && countDown === 0) {
            // If isLoading is true and countDown reaches 0, stop the loading
            setIsLoading(false);
        }

        // Clean up function
        return () => clearInterval(intervalId);
    }, [isLoading, countDown]);

    const handleFormSubmit = async e => {
        e.preventDefault();
        const userEmailWithId = users.map(user => ({ id: user.id, email: user.Email })); // Create an array of objects with id and email
        const foundUser = userEmailWithId.find(user => user.email === userEmail);
        const now = new Date();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(now.getDate()).padStart(2, '0');
        const year = now.getFullYear();
        const dateLastUsed = `${month}/${day}/${year}`; // Find the user object with the matching email
        window.scrollTo(0, 0)
        setIsLoading(true);
        const toolDocSnapshot = await getDoc(toolDoc);

        if (toolDocSnapshot.exists()) {
            const toolData = toolDocSnapshot.data();
            const currentUses = toolData.Uses;
            const updatedUses = currentUses + 1;
            await updateDoc(toolDoc, { Uses: updatedUses });
        }

        if (foundUser) {
            // If the user was found, update the 'TotalUses' field
            const userDocRef = doc(db, 'AI Tools Users', foundUser.id);
            const userDocSnap = await getDoc(userDocRef);
            const userData = userDocSnap.data();
            const currentTotalUses = userData.SyllabusGeneratorUses || 0;
            const updatedTotalUses = currentTotalUses + 1;

            await updateDoc(userDocRef, { SyllabusGeneratorUses: updatedTotalUses });
            await updateDoc(userDocRef, { DateLastUsed: dateLastUsed });
        } else {

            // If the user was not found, add a new document
            await addDoc(usersRef, {
                Name: userName,
                Email: userEmail,
                SyllabusGeneratorUses: 1,
                DateLastUsed: dateLastUsed
            });
        }

        await getMessages1(); // remove this and replace with your actual request
        setStep('step3');
        setCountDown(120);
    };

    useEffect(() => {
        if (templateResponse) {
            setTemplateResponseArray(templateResponse.content.toString().split(/\n/))
            setIsLoading(false);
        }
    }, [templateResponse])

    const getMessages1 = async () => {
        // console.log(prompt1)
        const prompt = prompt1
        const options = {
            method: "POST",
            body: JSON.stringify({
                message: prompt
            }),
            headers: {
                "Content-Type": "application/json"
            }
        }
        try {
            const response = await fetch("https://us-central1-teachingduringtheriseofai.cloudfunctions.net/app/completion", options)
            // const response = await fetch("http://localhost:3000/completion", options)
            const data = await response.json()
            setTemplateResponse(data.choices[0].message)
        } catch (error) {
            console.error(error)
            setErrorOccured(true)
            const toolDocSnapshot = await getDoc(toolDoc);
            if (toolDocSnapshot.exists()) {
                const currentErrors = docDetails.Errors
                const updatedErrors = currentErrors + 1
                await updateDoc(toolDoc, { Errors: updatedErrors })
            }
            await addDoc(errorRef, {
                Name: userName,
                Email: userEmail,
                Error: error,
                timeStamp: Timestamp.fromDate(new Date()),
            })
        }
    }


    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const closeNavOptions = () => {
        document.getElementById("barsBtn").style.display = "flex"
        document.getElementById("navPopup").style.display = "none"
    }

    const scrollTo = (string, num) => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById(string);
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        num == 1 ? closeNavOptions() : void (0)
    }


    return (
        <body>
            <Helmet>
                <meta charSet="utf-8" />
                <title>AI Tool: Syllabus Generator | Gruvy Education</title>
                <link   href="https://gruvyeducation.com/aitools/lessonplangenerator" />
                <meta
                    name="keywords"
                    content="Gruvy Education, AI in Education, AI Tools, Syllabus Generator, AI Syllabus Generator, AI for Educators, AI Teaching Resources"
                />
                <meta
                    name="description"
                    content="Discover how Gruvy Education's AI Syllabus Generator can help streamline creating Syllabuses."
                />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="AI Tool: Syllabus Generator | Gruvy Education" />
                <meta property="og:description" content="Discover how Gruvy Education's AI Syllabus Generator can help streamline creating Syllabuses." />
                <meta property="og:url" content="https://gruvyeducation.com/aitools/lessonplangenerator" />
                <meta property="og:type" content="website" />
            </Helmet>

            <div className="holder">
                <Nav />
                <header className="shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <section className="section-one bg-ghost-white">
                    <div className="title">Syllabus Generator</div>
                    <div className="container section-one-content">
                        <div className="aiToolsFormContainer container">
                            <div className="buttonContainer backButtonContainer">
                                <Link to='/aitools' className="backBtn">⬅ Back to AI Tools</Link>
                            </div>
                            <div className="buttonContainer">
                                <input className="resetButton" type="button" onClick={resetForm} value="Reset" />
                            </div>
                            <div className="margin"></div>
                            <form className="aiToolsForm" onSubmit={handleFormSubmit} id='aiToolsForm'>
                                {!errorOccured && !isLoading && step == 'step1' &&
                                    <div className='aiToolsFormContent'>
                                        <br />
                                        <h2 className='text-center facebookTitle white step2txt moreBottomMargin'>Complete the form to generate your syllabus. Keep it short and simple. Let AI handle the longer writing.</h2>
                                        <label className="aiToolsFormLabel" htmlFor="name">Name:</label><br />
                                        <input className="aiToolsFormInput" placeholder='First Last' required type="text" id="name" name="name" value={userName} onChange={e => setUserName(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="email">Email:</label><br />
                                        <input className="aiToolsFormInput" placeholder='Enter Email' required type="text" id="email" name="email" value={userEmail} onChange={e => setUserEmail(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="subject">Subject:</label><br />
                                        <input className="aiToolsFormInput" placeholder='e.g. Math' required type="text" id="subject" name="subject" value={subject} onChange={e => setSubject(e.target.value)} /><br />
                                        <label className="aiToolsFormLabel" htmlFor="grade">Grade Level:</label><br />
                                        <select required className="aiToolsFormSelect" id="grade" name="grade" value={grade} onChange={e => setGrade(e.target.value)}>
                                            <option value="Pre-School">Pre-School</option>
                                            <option value="Kindergarden">Kindergarden</option>
                                            <option value="1st-Grade">1st-Grade</option>
                                            <option value="2nd-Grade">2nd-Grade</option>
                                            <option value="3rd-Grade">3rd-Grade</option>
                                            <option value="4th-Grade">4th-Grade</option>
                                            <option value="5th-Grade">5th-Grade</option>
                                            <option value="6th-Grade">6th-Grade</option>
                                            <option value="7th-Grade">7th-Grade</option>
                                            <option value="8th-Grade">8th-Grade</option>
                                            <option value="9th-Grade">9th-Grade</option>
                                            <option value="10th-Grade">10th-Grade</option>
                                            <option value="11th-Grade">11th-Grade</option>
                                            <option value="12th-Grade">12th-Grade</option>
                                            <option value="College Freshman">College Freshman</option>
                                            <option value="College Sophmore">College Sophmore</option>
                                            <option value="College Junior">College Junior</option>
                                            <option value="College Senior">College Senior</option>
                                            <option value="College Masters Course">Masters Course</option>
                                            <option value="College Doctorate Course">Doctorate Course</option>
                                        </select>
                                        <br />

                                        <label className="aiToolsFormLabel" htmlFor="courseName">Course Name:</label><br />
                                        <input className="aiToolsFormInput" placeholder='e.g. MATH 129 Linear Algebra' required type="text" id="courseName" name="courseName" value={courseName} onChange={e => setCourseName(e.target.value)} /><br />

                                        <label className="aiToolsFormLabel" htmlFor="courseInfo">Course Information: (Title, number, credit hours, times, location.)</label><br />
                                        <textarea
                                            className="aiToolsFormInput"
                                            placeholder='Insert course information.'
                                            required
                                            id="courseInfo"
                                            name="courseInfo"
                                            value={courseInfo}
                                            onChange={e => setCourseInfo(e.target.value)}
                                            rows="4"
                                            cols="50"
                                        /> <br />
                                        <label className="aiToolsFormLabel" htmlFor="instructorInfo">Instructor Information: (Name, contact details, office hours.)</label><br />
                                        <textarea
                                            className="aiToolsFormInput"
                                            placeholder='Insert instructor information.'
                                            required
                                            id="instructorInfo"
                                            name="instructorInfo"
                                            value={instructorInfo}
                                            onChange={e => setInstructorInfo(e.target.value)}
                                            rows="4"
                                            cols="50"
                                        /> <br />
                                        <label className="aiToolsFormLabel" htmlFor="courseDescription">Course Description: (Brief overview, purpose, and alignment with curriculum.)</label><br />
                                        <textarea
                                            className="aiToolsFormInput"
                                            placeholder='Insert course description. Keep it short and simple. Let AI handle the longer writing.'
                                            required
                                            id="courseDescription"
                                            name="courseDescription"
                                            value={courseDescription}
                                            onChange={e => setCourseDescription(e.target.value)}
                                            rows="4"
                                            cols="50"
                                        /> <br />
                                        <label className="aiToolsFormLabel" htmlFor="learningObjectives">Learning Objectives: (Measurable objectives.) - Optional</label><br />
                                        <textarea
                                            className="aiToolsFormInput"
                                            placeholder='Insert learning objectives. Keep it short and simple. Let AI handle the longer writing.'
                                            id="learningObjectives"
                                            name="learningObjectives"
                                            value={learningObjectives}
                                            onChange={e => setLearningObjectives(e.target.value)}
                                            rows="4"
                                            cols="50"
                                        /> <br />
                                        <label className="aiToolsFormLabel" htmlFor="courseMaterials">Course Materials: (Required and recommended resources.) - Optional</label><br />
                                        <textarea
                                            className="aiToolsFormInput"
                                            placeholder='Insert course materials. Keep it short and simple. Let AI handle the longer writing.'
                                            id="courseMaterials"
                                            name="courseMaterials"
                                            value={courseMaterials}
                                            onChange={e => setCourseMaterials(e.target.value)}
                                            rows="4"
                                            cols="50"
                                        /> <br />
                                        <label className="aiToolsFormLabel" htmlFor="courseSchedule">Course Schedule: (Topics, assignments, deadlines, exams.) - Optional</label><br />
                                        <textarea
                                            className="aiToolsFormInput"
                                            placeholder='Insert course schedule. Keep it short and simple. Let AI handle the longer writing.'
                                            id="courseSchedule"
                                            name="courseSchedule"
                                            value={courseSchedule}
                                            onChange={e => setCourseSchedule(e.target.value)}
                                            rows="4"
                                            cols="50"
                                        /> <br />
                                        <label className="aiToolsFormLabel" htmlFor="grading">Assessment and Grading: (Methods, weightage, scale, policies.) - Optional</label><br />
                                        <textarea
                                            className="aiToolsFormInput"
                                            placeholder='Insert assessment and grading information. Keep it short and simple. Let AI handle the longer writing.'
                                            id="grading"
                                            name="grading"
                                            value={grading}
                                            onChange={e => setGrading(e.target.value)}
                                            rows="4"
                                            cols="50"
                                        /> <br />
                                        <label className="aiToolsFormLabel" htmlFor="attendance">Assessment and attendance: (Expectations, policies for late work or special circumstances.) - Optional</label><br />
                                        <textarea
                                            className="aiToolsFormInput"
                                            placeholder='Insert assessment and attendance information. Keep it short and simple. Let AI handle the longer writing.'
                                            id="attendance"
                                            name="attendance"
                                            value={attendance}
                                            onChange={e => setAttendance(e.target.value)}
                                            rows="4"
                                            cols="50"
                                        /> <br />
                                        <label className="aiToolsFormLabel" htmlFor="integrity">Academic Integrity: (Statement on academic honesty.) - Optional</label><br />
                                        <textarea
                                            className="aiToolsFormInput"
                                            placeholder='Insert academic integrity information. Keep it short and simple. Let AI handle the longer writing.'
                                            id="integrity"
                                            name="integrity"
                                            value={integrity}
                                            onChange={e => setIntegrity(e.target.value)}
                                            rows="4"
                                            cols="50"
                                        /> <br />
                                        <label className="aiToolsFormLabel" htmlFor="accessibility">Accessibility and Accommodations: (Services for students with disabilities.) - Optional</label><br />
                                        <textarea
                                            className="aiToolsFormInput"
                                            placeholder='Insert accessibility and accommodations information. Keep it short and simple. Let AI handle the longer writing.'
                                            id="accessibility"
                                            name="accessibility"
                                            value={accessibility}
                                            onChange={e => setAccessibility(e.target.value)}
                                            rows="4"
                                            cols="50"
                                        /> <br />
                                        <label className="aiToolsFormLabel" htmlFor="communication">Communication and Updates: (Methods for course-related announcements.) - Optional</label><br />
                                        <textarea
                                            className="aiToolsFormInput"
                                            placeholder='Insert communication and updates information. Keep it short and simple. Let AI handle the longer writing.'
                                            id="communication"
                                            name="communication"
                                            value={communication}
                                            onChange={e => setCommunication(e.target.value)}
                                            rows="4"
                                            cols="50"
                                        /> <br />
                                        <label className="aiToolsFormLabel" htmlFor="policy">Course Policies and Expectations: (Additional guidelines.) - Optional</label><br />
                                        <textarea
                                            className="aiToolsFormInput"
                                            placeholder='Insert course Policies and expectations. Keep it short and simple. Let AI handle the longer writing.'
                                            id="policy"
                                            name="policy"
                                            value={policy}
                                            onChange={e => setPolicy(e.target.value)}
                                            rows="4"
                                            cols="50"
                                        /> <br />
                                        <br />
                                        <br />

                                        <input className="aiToolsFormButton" type="submit" value="Generate Rubric" />

                                    </div>}
                                {
                                    !errorOccured && !isLoading && step == 'step3' &&
                                    <div className='aiToolsFormContent'>
                                        <br />
                                        <h2 className='text-center facebookTitle white step2txt moreBottomMargin'>{courseName} Syllabus:</h2>

                                        {templateResponseArray.map((para, index) => {
                                            return <p key={index} className="activityTxt white">{para}<br /></p>
                                        })}
                                        <div className="rateContainer">
                                            <label className='rateLabel'>Rate:</label>
                                            <ReactStars
                                                count={5}
                                                onChange={onStarClick}
                                                size={24}
                                                activeColor="#ffd700"
                                                style={{ marginTop: '8px' }}
                                            />
                                        </div>

                                        <button className="aiToolsFormButton" onClick={downloadAsWord}>Download as Word Document</button>
                                        {/* <button className="aiToolsFormButton" onClick={downloadAsPdf}>Download as PDF</button> */}
                                    </div>

                                }
                                {isLoading && <div className='loadingTxt step2txt'>Creating a syllabus for {courseName.toLowerCase()}{dots} <br /><br /> {countDown} Seconds Left</div>}
                                {errorOccured && <div className='loadingTxt'>Oops! We're experiencing some difficulty :( <br /><br /> We're sorry for the inconvenience and we're looking into it. Please try refreshing the page or come back a little later. Rest assured, our team is actively investigating this issue. If the problem continues, don't hesitate to reach out for assistance.</div>}
                            </form>
                        </div>
                    </div>
                </section>
                <section className="section-two" >
                    <h2 className='pollsTitle' id='about'>Learn more about Gruvy Education</h2>
                    <div className="container section-two-content">
                        <div className="accordion">
                            <Link to="/testimonials" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Testimonials</span>
                                </div>
                            </Link>
                            <Link to="/about" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">About Us</span>
                                </div>
                            </Link>
                            <a target="_blank" href="https://calendly.com/williamgrube/gruvy-education-meeting" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Schedule a Virtual Meeting</span>
                                </div>
                            </a>
                            <ScrollLink to="contact" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Send us a message</span>
                                </div>
                            </ScrollLink>
                        </div>
                        <div className="info text-center">
                            <div className="section-title">
                                <h2>Join our Facebook Group</h2>
                            </div>
                            <p className="text">Join our community of educators staying ahead of the curve with AI.</p>
                            <a target='_blank' href="https://www.facebook.com/groups/630420622459086/" className="btn">Join</a>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </body>
    );
}

export default SyllabusGenerator;
