import React, { useEffect } from 'react';
import Nav1 from './Nav';
import Delivery from '../images/Delivery.png'
import Bonus from '../images/Bonus.jpg'
import Guarantee from '../images/Guarantee.jpg'
import onehundred from "../images/100percent-circle.png";
import { useState } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import emailjs from "emailjs-com"
import { Helmet } from 'react-helmet';
import { useLayoutEffect } from 'react';
import Head from "../images/WilliamGrubeHeadshot.JPG";
import Footer from './Footer';
import GetStarted from './GetStarted';



const SalesPage = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const [showButton, setShowButton] = useState(false);

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    useEffect(() => {
        const checkScrollPosition = () => {
            if (!showButton && window.pageYOffset > 600) {
                setShowButton(true);
            } else if (showButton && window.pageYOffset <= 600) {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', checkScrollPosition);
        return () => window.removeEventListener('scroll', checkScrollPosition);
    }, [showButton]);

    const handleClick = () => {
        const element = document.getElementById('getStarted');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // useState hook to manage the state of the switch
    const [isSchool, setIsSchool] = useState(true);

    const toggleSwitch = () => {
        setIsSchool(!isSchool); // toggle the current value of isSchool
    };

    return (
        <div className="holder">
            <Helmet>
                <meta charSet="utf-8" />
                <title>AI Education Training Information | Gruvy Education</title>
                <link href="https://gruvyeducation.com/training-info" />
                <meta
                    name="keywords"
                    content="AI Education Information, AI Education Training, Gruvy Education, AI Training, AI Teaching, AI Training Info, AI in Schools"
                />
                <meta
                    name="description"
                    content="Explore our comprehensive AI Education training programs designed to revolutionize teaching methods. Learn more about our offerings at Gruvy Education."
                />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="AI Education Training Information | Gruvy Education" />
                <meta property="og:description" content="Explore our comprehensive AI Education training programs designed to revolutionize teaching methods. Learn more about our offerings at Gruvy Education." />
                <meta property="og:url" content="https://gruvyeducation.com/traininginfo" />
                <meta property="og:type" content="website" />
            </Helmet>

            <Nav1 />
            <header className="shortHeader">
                <div className="container header-content noBottomMargin">
                    <div className="header-text text-white text-center">
                    </div>
                </div>
            </header>
            <GetStarted />
            <div className="sales-page sales-container">
                <div className="title">Explore AI in Education Training Opportunities</div>

                {/* <div className="switch-container" data-checked={isSchool ? 'schools' : 'individuals'}>
                    <label className='switchLabels' data-label="individuals">Individual Educator</label>
                    <label className="switch">
                        <input type="checkbox" checked={isSchool} onChange={toggleSwitch} />
                        <span className="slider round"></span>
                    </label>
                    <label className='switchLabels' data-label="schools">School or District</label>
                </div> */}
                <div className='floatingNav salesNav'>
                    <div className="floatingNavContainer salesNavContainer">
                        <Link
                            activeClass='active'
                            to='info'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='floatingNavOption salesOption'
                        >
                            Instructor Information
                        </Link>
                        <Link
                            activeClass='active'
                            to='method'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='floatingNavOption salesOption'
                        >
                            Delivery Method
                        </Link>
                        <Link
                            activeClass='active'
                            to='testimonials'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='floatingNavOption salesOption'
                        >
                            Testimonials
                        </Link>

                        <Link
                            activeClass='active'
                            to='pricing'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='floatingNavOption salesOption'
                        >
                            Pricing
                        </Link>
                        <Link
                            activeClass='active'
                            to='getStarted'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='floatingNavOption salesOption'
                        >
                            Get started
                        </Link>
                    </div>
                </div>
                {isSchool ? (
                    <div className='trainingContent'>
                        <TrainingSection />
                        <PresenterInformation id="info" />
                        <DeliveryMethod id='method' />
                        <TestimonialsSection id='testimonials' />
                        <BonusesSection id='bonus' />
                        <Pricing id='pricing' />
                        <CallToActionSection id='getStarted' />
                    </div>
                ) : (
                    <div className='trainingContent'>
                        <IndividualTrainingSection />
                        <PresenterInformation id="info" />
                        <IndividualDeliveryMethod id='method' />
                        <IndividualTestimonialsSection id='testimonials' />
                        <IndividualBonusesSection id='bonus' />
                        <IndividualPricing id='pricing' />
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}
const TrainingSection = () => {
    return (
        <section className="training-section sales-section">
            <div className="sales-title">4-Hour AI in Education Training for K-12 Educators</div>
            {/* <h2 className="section-sub-title">Continued Education Credit Through North Dakota State University!</h2> */}
            <h2 className="urgent">Limited spots available due to high demand: <Link activeClass='active'
                to='getStarted'
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className='urgentLink1'>Get started</Link></h2>
            <p className="section-content">
                Sign up your school for an AI training program designed specifically for K-12 educators. This four-hour program is divided into four one-hour modules, each focusing on unique aspects of AI in education.
            </p>
            <br />
            <h3 className='sessionTitle'>Module 1</h3>
            <p className="section-content session-content">
                Get an introduction to AI tools in education, learn about their current state, future possibilities, and explore how AI is reshaping our approach to teaching and learning.
            </p>
            <br />
            <h3 className='sessionTitle'>Module 2</h3>
            <p className="section-content session-content">
                Dive deeper into creating assignments and assessments. This session will equip you with techniques to design questions that challenge students' understanding and critical thinking, making it impossible for AI tools like ChatGPT to provide ready-made answers.
            </p>
            <br />
            <h3 className='sessionTitle'>Module 3</h3>
            <p className="section-content session-content">
                Understand the ethics of AI technology. Learn strategies for integrating AI tools into classrooms responsibly and guiding your students towards their ethical use.
            </p>
            <br />
            <h3 className='sessionTitle'>Module 4</h3>
            <p className="section-content session-content">
                Discover how to use AI tools to enhance your professional life as an educator. Learn how AI can improve efficiency, streamline processes, and augment your teaching.
            </p>
            <br />
            <p className="section-content">
                Recognizing the diverse schedules and preferences of various schools, the course is designed with flexibility in mind. The total four hours of training (one hour each session) can be divided however you prefer, whether that's four 1-hour sessions, two 2-hour sessions, one 1-hour session, and one 3-hour session, and so on.
            </p>
        </section>

    );
}

const IndividualTrainingSection = () => {
    return (
        <section className="training-section sales-section">
            <h2 className="sales-title">Asynchronous AI in Education Training Course for K-12 Educators</h2>
            <h2 className="section-sub-title">You will recieve continued education credit through North Dakota State University!</h2>

            <h2 className="urgent">Limited spots available due to high demand: <Link activeClass='active'
                to='getStarted'
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className='urgentLink1'>Get started</Link></h2>
            <p className="section-content">
                This course is a succinct course designed for educators adapting to the digital age. It not only outlines AI's impact on education and provides strategies for ethical integration but also delves into creating AI-resistant content. The course empowers educators with the tools for innovative teaching methods, professional efficiency, and constructing content that retains its value in an AI-driven educational landscape.
            </p>
            <h3 className='sessionTitle'>You will: </h3>
            <p className="section-content session-content">
                • Learn the impact Artificial Intelligence has on education and student learning approaches.<br />
                • Learn how to use ChatGPT and an AI detection software.<br />
                • Analyze the potential advantages and limitations of AI tools like ChatGPT within an educational context.<br />
                • Learn strategies to create AI-resistant assignments, fostering critical thinking.<br />
                • Learn how to ethically integrate AI tools into the classroom<br />
                • Utilize AI, specifically ChatGPT, to enhance teaching efficiency.<br />
                • Develop a teaching unit that effectively resists AI shortcuts, ethically incorporates AI, and fosters critical thinking in students.<br />
                • Reflect on the experiences, challenges, insights, and potential improvements in AI integration in education.<br />
            </p>
        </section>
    );
}

const DeliveryMethod = (props) => {
    return (
        <section className="training-section sales-section" id={props.id}>
            <img className="section-image" src={Delivery} alt="Delivery" />
            <h2 className="section-title">Delivery Method</h2>
            <p className="section-content">
                Choose the mode of delivery that suits your school:
                <li>
                    <br />
                    <ul className='bulletPoint'>• Traditional in-person sessions</ul>
                    <ul className='bulletPoint'>• Real-time online sessions conducted via Zoom</ul>
                    <ul className='bulletPoint'>• Asynchronous learning that fits into your schools schedule</ul>
                </li>
            </p>
            {/* <br />
            <br />
            <p className="section-content">
                Take full advantage of this opportunity: Teachers who participate fully in the program are eligible for Continued Education credit through North Dakota State University. Don't miss out!
            </p> */}
        </section>

    );
}

const IndividualDeliveryMethod = (props) => {
    return (
        <section className="training-section sales-section" id={props.id}>
            <img className="section-image" src={Delivery} alt="Delivery" />
            <h2 className="section-title">Delivery Method</h2>
            <p className="section-content">
                <strong>Delivery method?</strong>
                <li>
                    <br />
                    <ul className='bulletPoint'>Flexible asynchronous learning that seamlessly integrates with your schedule.</ul>
                </li>
            </p>
            <br />
            <br />
            <p className="section-content">
                <strong>When?</strong>
                <li>
                    <br />
                    <ul className='bulletPoint'>10/01/2023 - 12/22/2023</ul>
                </li>
            </p>
            <br />
            <br />

            <p className="section-content">
                Teachers participating in the course will earn continuing education credit from North Dakota State University.
            </p>
        </section>

    );
}

const PresenterInformation = (props) => {
    return (
        <section className="training-section sales-section" id={props.id}>
            <img className="section-image morePaddingBottom" src={Head} alt="Presenter" />
            <h2 className="section-title">Instructor Information</h2>
            <a target="_blank" href='https://gruvyeducation.com/about' className="section-sub-title williamTitle">William Grube</a>
            <p className="section-content">
                William Grube is the founder of Gruvy Education. He has collaborated with over 100 schools nationwide, and his work has varied from providing  AI training to delivering keynotes. Currently pursuing an accelerated master's degree in computer science at North Dakota State University, William is well-versed in cutting-edge AI tools and technologies.
                <br />
                <br />
                Driven by a strong desire to contribute meaningfully to the field of education and artificial intelligence, William is applying his knowledge and skills to make a notable impact on the future of AI in education.
            </p>
            <br />
            <br />
            <br />
            <br />
            <h2 className="section-title">Accolades</h2>
            <p className="section-content">
                <li>
                    <ul className='bulletPoint'>
                        • Currently delivering AI in Education Training at 70+ School Districts
                    </ul>
                    <ul className='bulletPoint'>
                        • Has worked specifically on AI with the Minnesota Association of Secondary School Principals, Minnesota Elementary School Principals' Association, and North Dakota Council of Educational Leaders.
                    </ul>
                    {/* <ul className='bulletPoint'>
                        • Has delivered 30+ "Teaching During the Rise of AI" presentations at school districts nationwide.
                    </ul> */}
                    <ul className='bulletPoint'>
                        • Spoke at the Innovative School Summit in Las Vegas
                    </ul>
                </li>
            </p>
            <br />
            <br />
        </section>
    );
}

const TestimonialsSection = (props) => {
    return (
        <section className="testimonials-section sales-section" id={props.id}>
            <img className="section-image" src={onehundred} alt="Testimonials" />
            <br />
            <h2 className="section-title">Surveyed educators reported a full endorsement: 100% would recommend our training to peers.</h2>
            <br />
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    Every school district in America should have this training. Our kids would be serviced much better as a result and teachers' work load would be greatly reduced.
                </blockquote>
                <div></div>
                <p>
                    Participant in the AI in Education Training
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    I've been an educator for over 30 years. This is a top 5 all-time in-service. No fluff, real examples, a chance to experiment with the technology, and a lot to think about.
                </blockquote>
                <div></div>
                <p>
                    Participant in the AI in Education Training
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    My thoughts about Chat GPT coming into this training were that it was an insidious cheating tool. I learned that AI is around to stay, and we should not just pretend that it doesn't exist. In fact, if we incorporate it into education correctly, we can teach students that it is just a tool that can be helpful in their lives if used appropriately.
                </blockquote>
                <div></div>
                <p>
                    Participant in the AI in Education Training
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    This PD day was unlike any other in terms of engagement. William's presentation style was captivating, exuding confidence in his subject expertise. The content was highly informative, presented in a manner that made it effortless to comprehend and stay engaged with his teachings.
                </blockquote>
                <div></div>
                <p>
                    Participant in the AI in Education Training
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    You were unbelievably poised and eloquent. You were knowledgeable on the subject and provided realistic ways for educators to work with this technology instead of against it. I heard from many teachers that you were one of the most engaging professional development facilitators we've ever had.
                </blockquote>
                <div></div>
                <p>
                    Participant in the AI in Education Training
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    William/Gruvy Education is an emerging tour de force in teacher professional development. Every school will need someone like him to keep up with the pace of technology. I truly look at William as one of our key partners going forward.
                </blockquote>
                <div></div>
                <p>
                    Patrick Walsh &mdash; Superintendent, Belgrade-Brooten-Elrosa Public School District
                </p>
            </div>
        </section>
    );
}

const IndividualTestimonialsSection = (props) => {
    return (
        <section className="testimonials-section sales-section" id={props.id}>
            <img className="section-image" src={onehundred} alt="Testimonials" />
            <br />
            <h2 className="section-title">Surveyed educators reported a full endorsement: 100% would recommend our training to peers.</h2>
            <br />
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    Every school district in America should have this training. Our kids would be serviced much better as a result and teachers' work load would be greatly reduced.
                </blockquote>
                <div></div>
                <p>
                    Participant in the AI in Education Training
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    I've been an educator for over 30 years. This is a top 5 all-time in-service. No fluff, real examples, a chance to experiment with the technology, and a lot to think about.
                </blockquote>
                <div></div>
                <p>
                    Participant in the AI in Education Training
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    My thoughts about Chat GPT coming into this training were that it was an insidious cheating tool. I learned that AI is around to stay, and we should not just pretend that it doesn't exist. In fact, if we incorporate it into education correctly, we can teach students that it is just a tool that can be helpful in their lives if used appropriately.
                </blockquote>
                <div></div>
                <p>
                    Participant in the AI in Education Training
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    This PD day was unlike any other in terms of engagement. William's presentation style was captivating, exuding confidence in his subject expertise. The content was highly informative, presented in a manner that made it effortless to comprehend and stay engaged with his teachings.
                </blockquote>
                <div></div>
                <p>
                    Participant in the AI in Education Training
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    You were unbelievably poised and eloquent. You were knowledgeable on the subject and provided realistic ways for educators to work with this technology instead of against it. I heard from many teachers that you were one of the most engaging professional development facilitators we've ever had.
                </blockquote>
                <div></div>
                <p>
                    Participant in the AI in Education Training
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    William/Gruvy Education is an emerging tour de force in teacher professional development. Every school will need someone like him to keep up with the pace of technology. I truly look at William as one of our key partners going forward.
                </blockquote>
                <div></div>
                <p>
                    Patrick Walsh &mdash; Superintendent, Belgrade-Brooten-Elrosa Public School District
                </p>
            </div>
            <div className="testimonial">
                <blockquote>
                    <span className="stars">
                        &#9733; &#9733; &#9733; &#9733; &#9733;
                    </span>
                    Any education system would greatly benefit from his training and guidance.
                </blockquote>
                <div></div>
                <p>
                    Logan Greenhaw &mdash; Founder and CEO of Auto Classmate
                </p>
            </div>
        </section>
    );
}

const BonusesSection = (props) => {
    return (
        <section className="bonuses-section sales-section" id={props.id}>
            <img className="section-image" src={Bonus} alt="Bonuses" />
            <br />
            <h2 className="section-title">FREE Bonuses</h2>
            <p className="section-content bonuses">
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">One-on-one help for educators struggling with tech literacy.</div></div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">Earn four university-accredited badges/certificates with full participation. <u><a target='_blank' href='https://www.credly.com/organizations/north-dakota-state-university/collections/ai-in-education/badge_templates'>See Badges</a></u></div></div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">Easy-to-follow, step-by-step tutorials to bolster technical proficiency.</div></div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">On-demand access to recorded sessions for educators who couldn't attend live events.</div></div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">15 custom AI Tools designed for Educators.</div></div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">Access to extensive digital libraries of AI tools and learning materials.</div></div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">Ethical resources that guide educators on responsible and equitable use of AI in the classroom.</div></div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">Resources focusing on maintaining human-centric education, including strategies for integrating AI without compromising the personal touch in teaching.</div></div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">Detailed reports provided to admin staff to monitor participation.</div></div>
            </p>
        </section>
    );
}

const IndividualBonusesSection = (props) => {
    return (
        <section className="bonuses-section sales-section" id={props.id}>
            <img className="section-image" src={Bonus} alt="Bonuses" />
            <br />
            <h2 className="section-title">FREE Bonuses</h2>
            <p className="section-content bonuses">
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">One-on-one help for educators struggling with tech literacy.</div></div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">Easy-to-follow, step-by-step tutorials to bolster technical proficiency.</div></div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">Full participation earns you four university-accredited badges/certificates. See here: <u><a target='_blank' href='https://www.credly.com/organizations/north-dakota-state-university/collections/ai-in-education/badge_templates'>Badges</a></u></div></div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">On-demand access to recorded lectures.</div></div>

                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">15 custom AI Tools designed for Educators.</div></div>
                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">Access to extensive digital libraries of AI tools and learning materials.</div></div>

                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">Ethical resources that guide educators on responsible and equitable use of AI in the classroom.</div></div>

                <br />
                <div className="bonus"><div className="green">✓</div><strong>Bonus: </strong><div className="bonusText">Resources focusing on maintaining human-centric education, including strategies for integrating AI without compromising the personal touch in teaching.</div></div>
            </p>
        </section>
    );
}

const GuaranteeSection = (props) => {
    return (
        <section className="guarantee-section sales-section" id={props.id}>
            <img className="section-image" src={Guarantee} alt="Guarantee" />
            <h2 className="section-title">Money Back Guarantee</h2>
            <p className="moneybackGuarantee">If you're not completely satisfied after the first session, we guarantee a full refund of your payment.</p>
        </section>
    );
}
const Pricing = (props) => {
    const [selectedPricing, setSelectedPricing] = useState('in-person');

    const pricingDetails = {
        'in-person': (
            <div className="pricing-details" >
                <h3 className='pricing-catagory-title'>In-Person Pricing:</h3>
                <ul className='pricing-list'>
                    <li className='pricing-list-option'>• For a student population less than 400: $3,500 + $30 per educator</li>
                    <li className='pricing-list-option'>• For a student population between 400-999: $4,500 + $30 per educator</li>
                    <li className='pricing-list-option'>• For a student population over 999: $6,000 + $30 per educator</li>
                </ul>
                <h3 className='sessionTitle'>In-person Training Mileage:</h3>
                <p className="section-content session-content">
                    Travel costs are calculated at the current US Mileage Rate.
                </p>
                <h3 className='sessionTitle'>Continued Education Credit:</h3>
                <p className="section-content session-content">
                    Educators desiring to earn a continued education credit for this course are required to register through North Dakota State University. Please be aware that the minimum fee for course credit, set by NDSU, stands at $50. This fee is channeled directly to NDSU as it constitutes the lowest cost for securing a credit. Those educators opting to undertake this course for credit will be expected to successfully complete four distinct assignments and a final capstone project. Detailed instructions concerning these requirements will be comprehensively provided to all participating teachers during the inaugural session of the training.
                </p>
            </div>
        ),
        'virtual': (
            <div className="pricing-details">
                <h3 className='pricing-catagory-title'>Virtual Pricing:</h3>
                <ul className='pricing-list'>
                    <li className='pricing-list-option'>• For a student population less than 400: $2,500 + $30 per educator</li>
                    <li className='pricing-list-option'>• For a student population between 400-999: $3,500 + $30 per educator</li>
                    <li className='pricing-list-option'>• For a student population over 999: $5,000 + $30 per educator</li>
                </ul>
                <h3 className='sessionTitle'>Continued Education Credit:</h3>
                <p className="section-content session-content">
                    Educators desiring to earn a continued education credit for this course are required to register through North Dakota State University. Please be aware that the minimum fee for course credit, set by NDSU, stands at $50. This fee is channeled directly to NDSU as it constitutes the lowest cost for securing a credit. Those educators opting to undertake this course for credit will be expected to successfully complete four distinct assignments and a final capstone project. Detailed instructions concerning these requirements will be comprehensively provided to all participating teachers during the inaugural session of the training.
                </p>
            </div>
        ),
        'asyncronous': (
            <div className="pricing-details">
                <h3 className='pricing-catagory-title'>Asynchronous Pricing:</h3>
                <ul className='pricing-list'>
                    <li className='pricing-list-option'>• For a student population less than 400: $1,500 + $30 per educator</li>
                    <li className='pricing-list-option'>• For a student population between 400-999: $2,500 + $30 per educator</li>
                    <li className='pricing-list-option'>• For a student population over 999: $4,000 + $30 per educator</li>
                </ul>
                <h3 className='sessionTitle'>Continued Education Credit:</h3>
                <p className="section-content session-content">
                    Educators desiring to earn a continued education credit for this course are required to register through North Dakota State University. Please be aware that the minimum fee for course credit, set by NDSU, stands at $50. This fee is channeled directly to NDSU as it constitutes the lowest cost for securing a credit. Those educators opting to undertake this course for credit will be expected to successfully complete four distinct assignments and a final capstone project. Detailed instructions concerning these requirements will be comprehensively provided to all participating teachers during the inaugural session of the training.
                </p>
            </div>
        )
    }

    const handleChange = (event) => {
        setSelectedPricing(event.target.value);
    }

    return (
        <section className="guarantee-section sales-section" id={props.id}>
            <h2 className="section-title">Pricing</h2>
            <div className="radioButtonContainer">
                <div className="radio-button">
                    <input type="radio" id="in-person" name="pricing" value="in-person" checked={selectedPricing === 'in-person'} onChange={handleChange} />
                    <label htmlFor="in-person">In-person</label>
                </div>
                <div className="radio-button">
                    <input type="radio" id="virtual" name="pricing" value="virtual" checked={selectedPricing === 'virtual'} onChange={handleChange} />
                    <label htmlFor="virtual">Virtual</label>
                </div>
                <div className="radio-button">
                    <input type="radio" id="asyncronous" name="pricing" value="asyncronous" checked={selectedPricing === 'asyncronous'} onChange={handleChange} />
                    <label htmlFor="asyncronous">Asynchronous</label>
                </div>
            </div>
            <p>{pricingDetails[selectedPricing]}</p>
        </section>
    );
}

const IndividualPricing = (props) => {
    return (
        <section className="guarantee-section sales-section" id={props.id}>
            <div className="bonus signUpBox" id='getStarted'>To enroll, you will register via North Dakota State University. Click the button below to commence your enrollment process.</div>
            <div className="price-tag">
                <span className="course-price">Price: <a className="green">$125</a></span>
            </div>
            <a className="enrollBtn" target='_blank' href='https://northdakotastate-ndus.nbsstore.net/educ-2000-ai-in-educ-trainingonline-10-1-23-12-22-23'>Secure Your Spot Now</a>
            <a className="urgent urgentLink" target='_blank' href='https://northdakotastate-ndus.nbsstore.net/educ-2000-ai-in-educ-trainingonline-10-1-23-12-22-23'>Limited spots available due to high demand.</a>
        </section>
    );
}

const CallToActionSection = (props) => {
    return (
        <section className="guarantee-section sales-section" id={props.id}>
            <div className="bonus signUpBox" id='getStarted'>Arrange a meeting to initiate the enrollment process for your district. This meeting is purely informational and doesn't commit you to any decision.</div>
            <a className="enrollBtn" target='_blank' href='https://calendly.com/williamgrube/ai-in-education-training-info-meeting-gruvy-education'>Schedule Meeting</a>
            <a className="urgent urgentLink" target='_blank' href='https://calendly.com/williamgrube/ai-in-education-training-info-meeting-gruvy-education'>Limited spots available due to high demand.</a>
        </section>
    );
}

const IndividualCallToActionSection = (props) => {
    return (
        <section className="cta-section sales-section" id={props.id}>
            <h2 className="section-title" >Get Started Today!</h2>
            <button className="cta-button" onClick={() => alert("Schedule Meeting Button Clicked!")}>Schedule Meeting</button>
            <button className="cta-button" onClick={() => alert("Sign Up Button Clicked!")}>Sign Up</button>
        </section>
    );
}

export default SalesPage;