import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { useNavigate } from 'react-router-dom';
import Nav from './Nav'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Magic from "../images/magic.jpg"
import Library from '../images/library.jpg'
import Explore from '../images/Explore.png'
import US from '../images/usLogo.png'
import Tool from "../images/Pencil.png"
import Khan from '../images/Khan.png'
import Doc from '../images/doc.png'

const Landing = () => {

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    return (

        <body>
            <div className="holder">
                <Nav />
                <header className="header-blue shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    <div className="directions ">
                        <p>Welcome to our breakout session page! Check out the content below that we'll cover.</p>
                    </div>
                    <div className="title noMarginBottom">Ethical Use of AI in the Classroom.</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <ScrollLink activeClass="active" to="resources-section" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">🌳</span> Resources</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="activities-section" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">🎨</span> Activities</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="AITools" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Tool">🛠️</span> AI Tools</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="Library" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">📚</span> Libraries</div>
                            </ScrollLink>

                            <ScrollLink activeClass="active" to="AILinks" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Globe">🌐</span> Socials</div>
                            </ScrollLink>

                            <ScrollLink activeClass="active" to="contact" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Contact">📱</span> Contact</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <section id='resources-section' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🌳 Resources</h2>
                            <div className="tool-grid">
                                <a
                                    href="https://docs.google.com/document/d/1vmj03DQSovVUm0bTOsPgUH5yJCISilepKbAtzRW_JNc/edit?usp=sharing"
                                    target="_blank"
                                    className="tool-card"
                                >
                                    <div className="tool-icon">
                                        <img src={Doc} alt="Document Icon" />
                                    </div>
                                    <h3 className="tool-title">Pre-Activity Guidelines for Using ChatGPT in the Classroom</h3>
                                    <p className="tool-text">A succinct guide for educators on responsible ChatGPT usage.</p>
                                </a>

                                <a
                                    href="https://docs.google.com/document/d/17plpqHScRAei2lvkrJmE1Tgvmz530eb0BHVnL3f1_lc/edit?usp=sharing"
                                    target="_blank"
                                    className="tool-card"
                                >
                                    <div className="tool-icon">
                                        <img src={Doc} alt="Document Icon" />
                                    </div>
                                    <h3 className="tool-title">ChatGPT Classroom Use: Guidelines for Students</h3>
                                    <p className="tool-text">An easy-to-understand graphic for students on using ChatGPT responsibly.</p>
                                </a>

                                <a
                                    href="https://docs.google.com/document/d/1wvBOr8CyALglw5RBVp0VICiu5Xb169fBBlHg_4I4MbU/edit?usp=sharing"
                                    target="_blank"
                                    className="tool-card"
                                >
                                    <div className="tool-icon">
                                        <img src={Doc} alt="Document Icon" />
                                    </div>
                                    <h3 className="tool-title">AI in Education: Teacher's Checklist for Success</h3>
                                    <p className="tool-text">A practical checklist for teachers to effectively implement AI tools in the classroom.</p>
                                </a>

                                <a
                                    href="https://tech.ed.gov/ai/"
                                    target="_blank"
                                    className="tool-card"
                                >
                                    <div className="tool-icon">
                                        <img src={US} alt="U.S. Icon" />
                                    </div>
                                    <h3 className="tool-title">U.S. Department of Education on AI Safety</h3>
                                    <p className="tool-text">U.S. Department of Education analysis on AI's impact on education and AI safety.</p>
                                </a>
                            </div>
                        </section>
                        <section id='activities-section' className="course-section course-activities-section">
                            <h2 className="course-sub-title">🎨 Activities</h2>
                            <div className="activities-wrapper">
                                {/* Navigation bar */}
                                <div className="activity-nav-bar">
                                    <a
                                        href={`#1`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        1
                                    </a>
                                    <a
                                        href={`#2`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        2
                                    </a>
                                    <a
                                        href={`#3`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        3
                                    </a>
                                </div>
                                <div key="1" id="1" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Creating an AI-Integrated In-Class Activity with AI
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://docs.google.com/document/d/1vfR2w7mAysWTXXCqHlJJ-ZIJACWPhlF0NbsDErnR2To/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Doc} alt="Google Doc" />
                                                    <span className="link-label">Prompt</span>
                                                </a>
                                                <a className="course-link-icon" href="https://gruvyeducation.com/aitools/aiactivitygenerator" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Tool} alt="AI Tool" />
                                                    <span className="link-label">AI Tool</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Follow along with the instructor to create your AI-integrated in-class activity using AI. You have the option of utilizing ChatGPT and the Prompt document, or you can opt to use the AI tool instead.</p>
                                        </div>
                                        <div key="2" className="activity-question-container">
                                            <p className="poll-question question-number">Does this activity ethically integrate AI into the classroom?</p>
                                        </div>
                                        <div key="3" className="activity-question-container">
                                            <p className="poll-question question-number">Can you envision yourself using the content of this document to generate activities that would be suitable for your classroom?</p>
                                        </div>
                                    </form>
                                </div>
                                <div key="2" id="2" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Creating an AI-Integrated Group Project with AI
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                                <a className="course-link-icon" href="https://docs.google.com/document/d/1el6sBRKRnLYwb4lkaKUs3kg1u-meXdx4xjuASSQMegc/edit?usp=sharing" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Doc} alt="Google Doc" />
                                                    <span className="link-label">Prompt</span>
                                                </a>
                                                <a className="course-link-icon" href="https://gruvyeducation.com/aitools/aifacilitatedgroupproject" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Tool} alt="AI Facilitated Group Project Tool" />
                                                    <span className="link-label">AI Tool</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Follow along with the instructor to create your AI-integrated group project using AI. You have the option of utilizing ChatGPT and the Prompt document, or you can opt to use the AI tool instead.</p>
                                        </div>
                                        <div key="2" className="activity-question-container">
                                            <p className="poll-question question-number">Does this activity ethically integrate AI into the classroom?</p>
                                        </div>
                                        <div key="3" className="activity-question-container">
                                            <p className="poll-question question-number">Can you envision yourself using the content of this document to generate activities that would be suitable for your classroom?</p>
                                        </div>
                                    </form>
                                </div>
                                <div key="3" id="3" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Khan Academy Khanmigo Analysis
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Link Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://youtu.be/yEgHrxvLsz0" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={Khan} alt="Khan Academy Video" />
                                                    <span className="link-label">Video</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number">Do you believe that Khan Academy's approach to AI in education is ethical?</p>
                                        </div>
                                        <div key="2" className="activity-question-container">
                                            <p className="poll-question question-number">Do you think Khan Academy's way of using AI shows what the future will be like for AI in schools?</p>
                                        </div>
                                        <div key="3" className="activity-question-container">
                                            <p className="poll-question question-number">What, if any, changes would you suggest for Khan Academy's approach to AI in education?</p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                        <section name='AITools' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🛠️ Free AI Tools</h2>
                            <div className="tool-grid">
                                <a href="https://autoclassmate.io/tools/ai-powered-lesson-plan-generator/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Auto} alt="autoClassmate" />
                                    </div>
                                    <h3 className="tool-title">Auto Classmate</h3>
                                    <p className="tool-text">Automated lesson planning aligned with state standards.</p>
                                </a>
                                <a href="https://chat.openai.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={ChatGPT} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">ChatGPT</h3>
                                    <p className="tool-text">ChatGPT direct link.</p>
                                </a>
                                <a href="https://app.magicschool.ai/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Magic} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">Magic School</h3>
                                    <p className="tool-text">Your all-in-one AI toolkit for educators.</p>
                                </a>
                                <a href="https://web.diffit.me/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Diffit} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Diffit</h3>
                                    <p className="tool-text">Creates AI Generated Google Docs for Teachers.</p>
                                </a>
                                <a href="https://gptzero.me/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={GPTZero} alt="GPTZero" />
                                    </div>
                                    <h3 className="tool-title">GPTZero</h3>
                                    <p className="tool-text">AI Detefction Tool</p>
                                </a>
                            </div>
                        </section>
                        <section name='Library' className="course-section course-resources-section">
                            <h2 className="course-sub-title">📚 Libraries</h2>
                            <div className="tool-grid">
                                <a href="https://gruvyeducation.com/promptlibrary" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Library} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">ChatGPT Prompt Library</h3>
                                    <p className="tool-text">Collection of useful ChatGPT prompts for educators.</p>
                                </a>
                                <a href="https://gruvyeducation.com/aitools" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Library} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Educator AI Tools Library</h3>
                                    <p className="tool-text">Collection of useful AI tools for educators</p>
                                </a>

                            </div>
                        </section>
                        <section name='AILinks' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🌐 Socials</h2>
                            <div className="tool-grid">
                                <a href="https://www.facebook.com/groups/630420622459086/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Facebook} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Join our Facebook Group</h3>
                                    <p className="tool-text">Join our community of educators staying ahead of the curve with AI.</p>
                                </a>
                                <a href="https://twitter.com/Gruvy_edu" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={X} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">X</h3>
                                </a>
                                <a href="https://www.youtube.com/@GruvyEducation/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={YouTube} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">YouTube</h3>
                                </a>
                                <a href="https://www.linkedin.com/company/gruvy-education/about/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={LinkedIn} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">LinkedIn</h3>
                                </a>
                            </div>
                        </section>
                        <section name='NextSteps' className="course-section course-resources-section">
                            <h2 className="course-sub-title">✅ Next Steps</h2>
                            <div className="tool-grid">
                                <a href="https://gruvyeducation.com/traininginfo" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Explore} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Explore AI Training Opportunities for K-12 Educators</h3>
                                    <p className="tool-text">Join the 30+ schools participating in our AI in Education Training.</p>
                                </a>
                                {/* <a href="https://calendly.com/williamgrube/meet-william-at-ndcel" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Schedule} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Schedule a time to come see me today.</h3>
                                    <p className="tool-text">I will be set up in Hall B.</p>
                                </a> */}
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default Landing;
