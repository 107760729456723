import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { Link, useNavigate } from 'react-router-dom';
import Nav from './NavNDCEL'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Magic from "../images/magic.jpg"
import Library from '../images/library.jpg'
import Explore from '../images/Explore.png'
import TeachAI from '../images/TeachAI.jpg'
import Doc from '../images/doc.png'
import NDCEL from '../images/NDCEL.png'
import HumanCapitol from '../images/HumanCapitol3.png'
import Communications from '../images/Communications3.png'
import BusinessServices from '../images/BusinessServices3.png'
import TeachingLearning from '../images/TeachingLearning3.png'
import Form from '../images/form.png'
import NavDPI from './NavDPI';

const DPIHumanResources = () => {

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    function CopyToClipboard({ textToCopy, displayText }) {
        const [isCopied, setIsCopied] = useState(false);
        const [showNotification, setShowNotification] = useState(false);

        const handleCopy = (e) => {
            e.preventDefault();
            navigator.clipboard.writeText(textToCopy).then(() => {
                setIsCopied(true);
                setShowNotification(true);

                // Hide the notification after 1 second
                setTimeout(() => {
                    setShowNotification(false);
                }, 1000);

                // Reset the isCopied state after 2 seconds
                setTimeout(() => {
                    setIsCopied(false);
                }, 2000);
            });
        };

        return (
            <div>
                <div className="textToCopy" onClick={handleCopy}>
                    {displayText}
                    <span className={`copy-icon ${isCopied ? 'copied' : ''}`}>
                        {isCopied ? '✓' : '📋'}
                    </span>
                </div>
                {showNotification &&
                    <div className="copy-notification">
                        Text Copied!
                    </div>
                }
            </div>
        );
    }

    const prompts7 = [
        {
            id: 'basketballFilmGrading',
            directions: "Use this prompt in ChatGPT to generate a film grading sheet designed for a junior high basketball player. The sheet should help them self-assess their performance while watching game footage.",
            text: "Create a film grading sheet for a junior high basketball player to use while watching themselves play in a game. The sheet should include criteria for self-assessment covering key aspects of basketball gameplay, such as offensive skills, defensive skills, teamwork, decision-making, and effort. Ensure the criteria are clear and specific to help the player identify areas of strength and improvement."
        }
    ]


    const prompts6 = [
        {
            id: 'generalFundExplanation',
            directions: "Copy and paste the prompt below into ChatGPT to gain an understanding of the General Fund aspect from Rob’s presentation on North Dakota School Funding.",
            text: "Understand this slide from Rob’s presentation: General Fund: A school can levy up to 70 mills in the general fund without a vote of the people. The state assumes a general fund levy of 60 mills meaning that the equivalent revenue generated from 60 mills is deducted from the school’s state foundation aid payment. Miscellaneous Fund Levy: Capped at 12 Mills and can be levied by vote of the school board. This levy consolidated several smaller levies such as the technology and transportation levy. These dollars are co-mingled in the general fund. This is not subject to the 12% increase per year. Special Reserve Fund Levy: Districts can levy up to 3 mills per year in the Special Reserve Fund. By a vote of the school board, these dollars can be transferred into the general fund with certain limitations. This has a limit of 15 mills. NDCC 57-19."
        },
        {
            id: 'perPupilPaymentDetails',
            directions: "Use this prompt in ChatGPT to understand the details of the current per pupil payment in North Dakota's school funding for the 2023/2024 school year.",
            text: "Also, take into account and understand this information: The current per-pupil payment of $10,646 represents the funding necessary for the 2023/2024 school year to provide an adequate education for all ND students. The value of 60 mills and 75% of in-lieu taxes from each school district are deducted from $10,646 to provide the local school district’s contribution toward adequate funding. Beyond this funding, school districts are able to levy an additional 10 general fund mills and 12 mills miscellaneous mills to account for any other needs as determined by the local school district."
        },
        {
            id: 'schoolFundingSimplified',
            directions: "Paste this prompt into ChatGPT to get a simplified explanation of North Dakota school funding, specifically for someone unfamiliar with the topic.",
            text: "Using the information above, explain this to someone who doesn't understand school funding at all. Reference the 60 mill deduct, 10 mills of discretionary, plus the 12 mills of miscellaneous levying authority in less than five sentences."
        },
    ]


    const prompts5 = [
        {
            id: 'cleaningDuties',
            directions: "Copy and paste the prompt below into ChatGPT to generate a comprehensive list of cleaning duties for a custodian at an elementary school classroom.",
            text: "Create a list of cleaning duties for a custodial at an elementary school for a classroom. Give me daily tasks, weekly tasks, monthly tasks, seasonal tasks, and as-needed tasks."
        },
        {
            id: 'shortenedDuties',
            directions: "Copy and paste the prompt below into ChatGPT to get a condensed version of the cleaning duties list. This version is useful for quick reference or for creating brief duty summaries.",
            text: "Ruduce/shorten list."
        },
    ]


    const prompts1 = [
        {
            id: 'teacher-coaching-ap-physics',
            directions: "Copy and paste the prompt below into ChatGPT for assistance in coaching an employee.",
            text: `Act as if you are a senior administrator at the North Dakota Department of Public Instruction, tasked with coaching a departmental team on improving their educational policy development and implementation processes. Although you might not have the in-depth expertise in every educational field or policy area your team covers, you've noticed a disconnect between the policies they develop and the tangible improvements in educational outcomes across schools in North Dakota. The team is known for their innovative ideas and commitment to education, yet there seems to be a lack of structured approach grounded in educational standards and evidence-based outcomes indicating real progress in student and teacher experiences state-wide. Draft some notes for your evaluations of the team or key points to discuss in your coaching sessions, focusing on aligning their efforts more closely with state educational standards and demonstrating clear, measurable impacts on educational quality.`.split('\n')
        },
    ];

    const prompts2 = [
        {
            id: 'tech-coach-improvement-plan',
            directions: "Copy and paste the prompt below into ChatGPT for assistance in creating an improvement plan for a consultant.",
            text: `Act as you're an administrator at the North Dakota Department of Public Instruction, overseeing specialized education programs. You're facing challenges with a key consultant whose demeanor hasn't been conducive to team collaboration, and there hasn't been noticeable progress in their area of work for over a year. Draft an improvement plan that outlines actionable steps and activities for enhancing the consultant's effectiveness and fostering a positive, results-driven environment within your specialized programs.`.split('\n')
        },
    ];

    const prompts3 = [
        {
            id: 'role-play-registrar-conversation',
            directions: "Copy and paste the prompt below into ChatGPT for assistance in role-playing a sensitive conversation with a school registrar.",
            text: `Act as if you are a director within the North Dakota Department of Public Instruction, and you've been informed of allegations against one of your key policy advisors for inappropriate conduct on social media. Your preliminary investigation suggests these claims, originating from an anonymous account, lack substantiation. Knowing your advisor to be particularly sensitive, you're concerned that discussing these allegations might push them to resign. Role-play this delicate conversation with me to prepare for addressing the situation in a way that is both supportive and constructive.`.split('\n')
        },
    ];

    const prompts4 = [
        {
            id: 'role-play-registrar-conversation',
            directions: "Copy and paste the prompt below into ChatGPT for assistance in generating kindly worded suggestions for improvement.",
            text: `Please offer a kindly worded, constructive suggestion for a team member at the North Dakota Department of Public Instruction to enhance their [specific area needing improvement, e.g., 'policy development strategies', 'stakeholder engagement practices', 'implementation of educational initiatives']. The feedback should be encouraging, focusing on personal and professional development within the context of educational policy and administration.`.split('\n')
        },
    ];

    return (
        <body>
            <div className="holder">
                <NavDPI module="human" />
                <header className="shortHeader header-purple">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />
                <section className="section-one bg-ghost-white lessPaddingTop">
                    {/* <img className='breakout-title-img' src={NDCEL}/> */}
                    <div className="title noMarginBottom breakout-title">Using AI in Human Resources</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <Link to="/nddpi" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">⬅️🏡</span> Back to Home</div>
                            </Link>
                            <ScrollLink activeClass="active" to="william" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Tool">🧑‍🤝‍🧑🏛️</span> Human Resources Activities</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="breakout" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">🗨️👥</span> Breakout Sessions</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <section id='william' className="course-section course-activities-section">
                            <h2 className="course-sub-title">🧑‍🤝‍🧑🏛️ Human Resources Activities</h2>
                            <div className="activities-wrapper">
                                {/* Navigation bar */}
                                <div className="activity-nav-bar">
                                    <a
                                        href={`#1`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        1
                                    </a>
                                    <a
                                        href={`#2`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        2
                                    </a>
                                    <a
                                        href={`#3`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        3
                                    </a>
                                    <a
                                        href={`#4`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        4
                                    </a>
                                    <a
                                        href={`#5`}
                                        className={"activity-nav-item redBackground"}
                                    >
                                        5
                                    </a>
                                </div>
                                <div key="1" id="1" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Leveraging ChatGPT for Coaching Employees
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts1.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text.map((line, lineIndex) => (
                                                    <React.Fragment key={lineIndex}>
                                                        <p>{line}</p>
                                                        {line === '' && <br />}
                                                    </React.Fragment>
                                                ))} />
                                            </div>
                                        ))}

                                    </form>
                                </div>
                                <div key="2" id="2" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Optimizing Improvement Plans with ChatGPT in Specialized Fields
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts2.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text.map((line, lineIndex) => (
                                                    <React.Fragment key={lineIndex}>
                                                        <p>{line}</p>
                                                        {line === '' && <br />}
                                                    </React.Fragment>
                                                ))} />
                                            </div>
                                        ))}

                                    </form>
                                </div>
                                <div key="3" id="3" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Enhancing Role-Play Conversations with ChatGPT
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts3.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text.map((line, lineIndex) => (
                                                    <React.Fragment key={lineIndex}>
                                                        <p>{line}</p>
                                                        {line === '' && <br />}
                                                    </React.Fragment>
                                                ))} />
                                            </div>
                                        ))}

                                    </form>
                                </div>
                                <div key="4" id="4" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Generating Kindly Worded Suggestions for Improvement with ChatGPT
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts4.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text.join('\n')} displayText={prompt.text.map((line, lineIndex) => (
                                                    <React.Fragment key={lineIndex}>
                                                        <p>{line}</p>
                                                        {line === '' && <br />}
                                                    </React.Fragment>
                                                ))} />
                                            </div>
                                        ))}

                                    </form>
                                </div>
                                <div key="5" id="5" className="course-content course-activity-content">
                                    {/* Title */}
                                    <h3 className="activity-title">
                                        <span className="activity-number"></span>
                                        Leveraging ChatGPT To Explaining Something Complex in Understandable Terms
                                    </h3>
                                    {/* List of Links */}
                                    <div className="links-section">
                                        <div className="course-links-wrapper">
                                            <h3 className="links-title">🔗 Links Needed for Activity:</h3>
                                            <div className="course-links-container">
                                                <a className="course-link-icon" href="https://chat.openai.com/" target="_blank" rel="noreferrer">
                                                    <img className="link-icon" src={ChatGPT} alt="ChatGPT" />
                                                    <span className="link-label">ChatGPT</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Questions with Input Fields */}
                                    <form className="activity-form">
                                        <div key="1" className="activity-question-container">
                                            <p className="poll-question question-number breakout-directions">Open up ChatGPT. Link can be found in the "Links Needed for Activity" section above.</p>
                                        </div>
                                        {prompts6.map((prompt, index) => (
                                            <div key={prompt.id} className="activity-question-container">
                                                <p className="poll-question question-number breakout-directions">{prompt.directions}</p>
                                                <CopyToClipboard textToCopy={prompt.text} displayText={prompt.text} />
                                            </div>
                                        ))}
                                    </form>
                                </div>
                            </div>
                        </section>
                        <section name='breakout' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🗨️👥 Breakout Sessions</h2>
                            <div className="tool-grid">
                                <Link to="/nddpi/humanresources" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={HumanCapitol} alt="autoClassmate" />
                                    </div>
                                    <h3 className="tool-title">Human Resources</h3>
                                    <p className="tool-text">Coaching employees, Improvement Plans, Role-Play Conversations.</p>
                                </Link>
                                <Link to="/nddpi/businessservices" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={BusinessServices} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">Business Services</h3>
                                    <p className="tool-text">Writing RFPs, Reviewing Bids, Writting Board Memos, Drafting Contracts.</p>
                                </Link>
                                <Link to="/nddpi/teachingandlearning" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={TeachingLearning} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">Teaching and Learning</h3>
                                    <p className="tool-text">Newsletters, Emails, Lesson Plans, Rubrics, Student Feedback.</p>
                                </Link>
                            </div>
                        </section>
                        <section name='NextSteps' className="course-section course-resources-section">
                            <h2 className="course-sub-title">✅ Next Steps</h2>
                            <div className="tool-grid">
                                <a href="https://youtu.be/kap60trj-K8" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={YouTube} alt="Youtube" />
                                    </div>
                                    <h3 className="tool-title">Learn about Gruvy Ecucation's AI Training Opportunities for K-12 Educators</h3>
                                    <p className="tool-text">Less than 5 minute explanation video.</p>
                                </a>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_2SwagH33-OQEOTcdQJv4Me0_IIiyAPYqF6NyHY0IYYvSTQ/viewform?usp=sf_link" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Form} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Bring Gruvy Education's AI Training to Your Educators</h3>
                                    <p className="tool-text">Join the 40+ schools participating in our AI in Education Training.</p>
                                </a>
                                {/* <a href="https://calendly.com/williamgrube/meet-william-at-ndcel" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Schedule} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Schedule a time to come see me today.</h3>
                                    <p className="tool-text">I will be set up in Hall B.</p>
                                </a> */}
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default DPIHumanResources;
