import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Nav from './Nav'
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import Logo from "../images/logo-no-background.png"
import Footer from './Footer';
import PDF from '../files/A Schools Guide to Student Data Privacy With AI.pdf'

const Home = () => {
    const openPDF = () => {
        window.open(PDF, '_blank');
    }
    return (
        <body>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Gruvy Education</title>
                <link rel="canonical" href="https://gruvyeducation.com/" />
                <meta
                    name="keywords"
                    content="Gruvy Education, Gruvy, AI in Education, AI Training, AI Tools, Education Technology, EdTech, Prompt Library, AI Curriculum, Future-Proof Education, William Grube"
                />
                <meta
                    name="description"
                    content="Leveraging the transformative potential of AI, Gruvy Education develops solutions to bring education into the 21st century. We provide AI-powered tools and training to help educators create engaging learning environments and prepare students for an AI-driven world."
                />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="AI in Education, Training & Tools | Gruvy Education" />
                <meta property="og:description" content="Leveraging the transformative potential of AI, Gruvy Education develops solutions to bring education into the 21st century. We provide AI-powered tools and training to help educators create engaging learning environments and prepare students for an AI-driven world." />
                <meta property="og:url" content="https://gruvyeducation.com/" />
                <meta property="og:type" content="website" />
            </Helmet>

            <div className="holder">
                <Nav />
                <header className="header">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                            {/* <h1 className="text-white subTitle topSubTitle">Adapting education to the AI tools that are present</h1> */}
                            <h1 className="text-white subTitle topSubTitle">We provide AI training to schools and major educational associations.</h1>
                            {/* <div className="btn-group">
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    window.open("https://gruvyeducation.com/traininginfo")
                                }} className="homebtn" type="button">Explore Training Opportunities</button>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    window.open("https://gruvyeducation.com/training")
                                }} className="homebtn" type="button">Training Portal</button>
                            </div> */}
                        </div>
                        {/* <br />
                        <br /> */}

                        {/* <a><img src="https://www.freeiconspng.com/uploads/artificial-intelligence-png-2.png" width="350" /></a> */}
                    </div>
                </header>
                {/* <div className="mini-navbar">
                        <ul>
                            <li><a href="#training-info">Training Info</a></li>
                            <li><a href="#training">Training Portal</a></li>
                            <li><a href="#presentation-info">Presentation Info</a></li>
                            <li><a href="#presentation-info">Presentation Portal</a></li>
                        </ul>
                    </div> */}
                {/* <section id='solutions' className="section-one bg-ghost-white noMarginTop">
                    <div className="container homepage-sections noMarginTop">
                        <div className="section presentation-info-section noMarginTop">
                            <Link to="/presentationinfo" className="section-content text-center bg-white">
                                <img src={Explore2} />
                                <h3>Consider 'Teaching During the Rise of AI' for Your District</h3>
                                <p className="text">Discover more about this highly acclaimed presentation, already delivered at 30+ schools nationwide and featured at the Innovative Schools Summit.</p>
                            </Link>
                        </div>
                    </div>

                </section> */}
                {/* <section id='solutions' className="section-one bg-ghost-white">
                    <div className="title">Gruvy Education</div>
                    <div className="container section-one-content">
                        <div className="grid">
                            <Link to="/presentationinfo" className="grid-item text-center bg-white">
                                <img src={Explore2} />
                                <h3>Consider 'Teaching During the Rise of AI' for Your District</h3>
                                <p className="text">Discover more about this highly acclaimed presentation, already delivered at 30+ schools nationwide and featured at the Innovative Schools Summit.</p>
                            </Link>

                            <Link to="/traininginfo" className="grid-item text-center bg-white">
                                <img src={Explore} />
                                <h3>Explore AI Training Opportunities for K-12 Educators</h3>
                                <p className="text">Join the hundreds of educators participating in our AI in Education Training.</p>
                            </Link>
                            <Link to="/presentation" className="grid-item text-center bg-white">
                                <img src={Presentation} />
                                <h3>Teaching During the Rise of AI Presentation</h3>
                                <p className="text">Press here to access presentation materials.</p>
                            </Link>
                            <Link to="/training" className="grid-item text-center bg-white">
                                <img src={GroupCourse} />
                                <h3>School-Wide AI in Education Training Portal</h3>
                                <p className="text">Press here and select your school to enter your schools training portal.</p>
                            </Link>
                            <a target="_blank" href="https://apps2.ndsu.edu/dce-course-list/classdetails/ClassObj::311" className="grid-item text-center bg-white">
                                <img src={Course} />
                                <h3>Asynchronous AI in Education Training Course</h3>
                                <p className="text">Earn a continued education credit while learning to confidently navigate Artificial Intelligence in education.</p>
                            </a>
                            <Link to="/aitools" className="grid-item text-center bg-white">
                                <img src={Pencil} />
                                <h3>AI Tools</h3>
                                <p className="text">AI Tools for Educators</p>
                            </Link>
                            <Link to="/aitools" className="grid-item text-center bg-white">
                                <img src={Book} />
                                <h3>ChatGPT Prompt Library</h3>
                                <p className="text">Collection of useful ChatGPT prompts for educators.</p>
                            </Link>
                        </div>
                    </div>

                </section> */}
                <div className="mini-navbar">
                    <ul>
                        <li><ScrollLink
                            activeClass='active'
                            to='train'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='cursorPointer'
                        >AI in Education Training</ScrollLink></li>
                        <li><Link
                            activeClass='active'
                            to='/testimonials'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='cursorPointer'
                        >Testimonials</Link></li>
                        {/* <li><ScrollLink
                            activeClass='active'
                            to='breakout'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='cursorPointer'
                        >Breakout Sessions</ScrollLink></li> */}
                        {/* <li><ScrollLink
                            activeClass='active'
                            to='keynote'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='cursorPointer'
                        >Keynote Speaking</ScrollLink></li> */}
                        <li><Link
                            activeClass='active'
                            to='/promptlibrary'
                            className='cursorPointer'
                        >Prompt Library</Link></li>
                        <li><a
                            activeClass='active'
                            href='https://direct-ai-updates-for-school-admins.beehiiv.com/subscribe'
                            className='cursorPointer'
                            target='_blank'
                        >Admin Exclusive Newsletter</a></li>
                        
                        {/* <li><Link
                            activeClass='active'
                            to='/promptlibrary'
                            className='cursorPointer'
                        >AI Tools Library</Link></li> */}
                        {/* <li><ScrollLink
                            activeClass='active'
                            to='free'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='cursorPointer'
                        >Free Resources</ScrollLink></li> */}
                        {/* <li><ScrollLink
                            activeClass='active'
                            to='contact'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='cursorPointer'
                        >Contact Us</ScrollLink></li>
                        <li><ScrollLink
                            activeClass='active'
                            to='about'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}
                            className='cursorPointer'
                        >About Us</ScrollLink></li> */}
                    </ul>
                </div>
                <div className="subTitle2 bigSub homeSubTitle" id='train' >AI in Education Training</div>
                <div class="ag-format-container">
                    <div class="ag-courses_box">
                        <div class="ag-courses_item">
                            <Link to="/training" class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>
                                <div class="ag-courses-item_title">
                                    AI in Education Training Portal
                                </div>
                                <div class="ag-courses-item_date-box">
                                    <span class="ag-courses-item_date">
                                        Press here to enter your schools training portal.
                                    </span>
                                </div>
                            </Link>
                        </div>
                        <div class="ag-courses_item">
                            <Link to="/traininginfo" class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>
                                <div class="ag-courses-item_title">
                                    Explore AI Training Opportunities for K-12 Educators
                                </div>

                                <div class="ag-courses-item_date-box">
                                    <span class="ag-courses-item_date">
                                        Limited spots available.
                                    </span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* <div className="subTitle2 bigSub homeSubTitle" id='breakout' >Breakout Sessions</div>
                <div class="ag-format-container">
                    <div class="ag-courses_box">
                        <div class="ag-courses_item">
                            <Link to="/breakout1" class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>
                                <div class="ag-courses-item_title">
                                    Breakout 1
                                </div>
                                <div class="ag-courses-item_date-box">
                                    <span class="ag-courses-item_date">
                                        Crafting Questions to Promote Critical Thinking and Discourage AI-Assisted Cheating.
                                    </span>
                                </div>
                            </Link>
                        </div>
                        <div class="ag-courses_item">
                            <Link to="/breakout2" class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>
                                <div class="ag-courses-item_title">
                                    Breakout 2
                                </div>
                                <div class="ag-courses-item_date-box">
                                    <span class="ag-courses-item_date">
                                        Maximizing the potential of AI to enhance efficiency As an Educator.
                                    </span>
                                </div>
                            </Link>
                        </div>
                        <div class="ag-courses_item ag-courses_item2">
                            <Link to="/breakout3" class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>
                                <div class="ag-courses-item_title">
                                    Breakout 3
                                </div>
                                <div class="ag-courses-item_date-box">
                                    <span class="ag-courses-item_date">
                                        Ethical Use of AI in the Classroom.
                                    </span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div> */}
                {/* <div className="subTitle2 bigSub homeSubTitle" id='keynote' >Teaching During the Rise of AI Keynote</div>
                <div class="ag-format-container">
                    <div class="ag-courses_box">
                        <div class="ag-courses_item ag-courses_item1">
                            <Link to="/presentation" class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>
                                <div class="ag-courses-item_title">
                                    Presentation Portal for Audience Members.
                                </div>
                                <div class="ag-courses-item_date-box">
                                    <span class="ag-courses-item_date">
                                        Click Here if You Are in the Audience to Join the Fun!
                                    </span>
                                </div>
                            </Link>
                        </div>
                        <div class="ag-courses_item ag-courses_item1">
                            <Link to="/presentationinfo" class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>
                                <div class="ag-courses-item_title">
                                    Consider 'Teaching During the Rise of AI' for Your Conference or Disctrict
                                </div>

                                <div class="ag-courses-item_date-box">
                                    <span class="ag-courses-item_date">
                                        Keynote Presentation Information.
                                    </span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div> */}
                <div className="subTitle2 bigSub homeSubTitle" id='free' >Free Resources for Educators</div>
                <div class="ag-format-container">
                    <div class="ag-courses_box">
                        <div class="ag-courses_item ag-courses_item2">
                            <Link to="/promptlibrary" class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>
                                <div class="ag-courses-item_title">
                                    Prompt Library
                                </div>
                                <div class="ag-courses-item_date-box">
                                    <span class="ag-courses-item_date">
                                        ChatGPT Prompt Library for Educators.
                                    </span>
                                </div>
                            </Link>
                        </div>
                        <div class="ag-courses_item ag-courses_item2">
                            <Link to="/aitools" class="ag-courses-item_link">
                                <div class="ag-courses-item_bg"></div>
                                <div class="ag-courses-item_title">
                                    AI Tools Library
                                </div>
                                <div class="ag-courses-item_date-box">
                                    <span class="ag-courses-item_date">
                                        AI Tools for Educators
                                    </span>
                                </div>
                            </Link>
                        </div>
                        <div className="ag-courses_item ag-courses_item2">
                            <a onClick={openPDF} className="ag-courses-item_link cursorPointer">
                                <div className="ag-courses-item_bg"></div>
                                <div className="ag-courses-item_title">
                                    A Schools Guide to Student Data Privacy With AI
                                </div>
                                <div className="ag-courses-item_date-box">
                                    <span className="ag-courses-item_date">
                                        Guide to Student Data Privacy With AI
                                    </span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <section className="section-two" >
                    <h2 className='pollsTitle' id='about'>Learn more about Gruvy Education</h2>
                    <div className="container section-two-content">
                        <div className="accordion">
                            <Link to="/testimonials" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Testimonials</span>
                                </div>
                            </Link>
                            <Link to="/about" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">About Us</span>
                                </div>
                            </Link>
                            <a target="_blank" href="https://calendly.com/williamgrube/gruvy-education-meeting" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Schedule a Virtual Meeting</span>
                                </div>
                            </a>
                            <ScrollLink to="contact" className="accordion-item">
                                <div className="accordion-item-icon">
                                    <img classname='littlelogo' src={Logo} />
                                </div>
                                <div className="accordion-item-content">
                                    <span className="text-blue accordion-title">Send us a message</span>
                                </div>
                            </ScrollLink>
                        </div>
                        <div className="info text-center">
                            <div className="section-title">
                                <h2>Join our Facebook Group</h2>
                            </div>
                            <p className="text">Join our community of educators staying ahead of the curve with AI.</p>
                            <a target='_blank' href="https://www.facebook.com/groups/630420622459086/" className="btn">Join</a>
                        </div>
                    </div>
                </section>
                {/* <section className="section-four">
                    <div className="container section-four-content">
                        <div className="info text-center">
                            <div className="section-title">
                                <h2 className='text-center white'>Testimonials</h2>
                            </div>
                            <p className="text text-center wider ">Take a look at what our satisfied value consumers have to say. Take it from the educators we've helped: our program has made a positive impact on their students' learning outcomes.</p>
                        </div>
                    </div>
                    <Link to="/testimonials" className="btn get-app-btn">View Testimonials</Link>
                </section>
                <section className="section-five bg-ghost-white">
                    <div className="container section-four-content">
                        <div className="info text-center">
                            <div className="section-title">
                                <h2 className='text-center facebookTitle black'>Join our community of educators staying ahead of the curve with AI.</h2>
                            </div>
                        </div>
                    </div>
                    <a target="_blank" href="https://www.facebook.com/groups/630420622459086/" className="btn get-app-btn ">Join Group For Free</a>
                </section>
                <section id='meeting' className="section-four">
                    <div className="container section-four-content">
                        <div className="info text-center">
                            <div className="section-title">
                                <h2 className='text-center white'>Adapt to the times. </h2>
                            </div>
                            <p className="text text-center wider ">Like many other schools have, enhance your knowledge of AI through our presentations and training programs. Schedule a 15 minute meeting with us today and let us show you how our expertise can help you stay ahead of the curve in this rapidly evolving field.</p>
                        </div>
                    </div>
                    <a target="_blank" href="https://calendly.com/williamgrube/gruvy-education-meeting" className="btn get-app-btn">Schedule Meeting</a>
                </section> */}
                <Footer />
            </div>

        </body >
    );
}

export default Home;
