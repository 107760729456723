import React from 'react';
import Logo from "../images/logo-no-background.png";
import emailjs from "emailjs-com"
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'


const Nav1 = () => {
    const navigate = useNavigate();
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const openNavOptions = () => {
        document.getElementById("barsBtn").style.display = "none"
        document.getElementById("navPopup").style.display = "flex"
        document.getElementById("mainLogo").style.display = "none"

    }

    const closeNavOptions = () => {
        document.getElementById("barsBtn").style.display = "flex"
        document.getElementById("navPopup").style.display = "none"
        document.getElementById("mainLogo").style.display = "flex"

    }

    const scrollTo = (string, num) => {
        document.getElementById("mainLogo").style.display = "flex"

        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById(string);
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        num == 1 ? closeNavOptions() : void (0)
    }

    function toAbout() {
        navigate("/about")
    }
    return (
        <nav className="navbar">
            <div className="mobileNav">
                <button id='barsBtn' onClick={openNavOptions}><FontAwesomeIcon className="bars" icon={faBars} /></button>
                <div className="navPopup" id='navPopup'>
                    <div className="mobileNavOption">
                        <button onClick={closeNavOptions} className="mobileNavLink">Exit</button>
                    </div>
                    <div className="mobileNavOption">
                        <Link to="/about" className="mobileNavLink">About</Link>
                    </div>
                    <div className="mobileNavOption">
                        <Link to="/training" className="mobileNavLink">Training Portal</Link>
                    </div>
                    {/* <div className="mobileNavOption">
                        <Link to="/testimonials" className="mobileNavLink">Training Testimonials</Link>
                    </div> */}
                    <div className="mobileNavOption">
                        <Link to="/traininginfo" className="mobileNavLink">Explore Training Opportunities</Link>
                    </div>
                    <div className="mobileNavOption">
                        <a target='_blank' href="https://direct-ai-updates-for-school-admins.beehiiv.com/subscribe?ref=GOT6dJ1dV5" className="mobileNavLink">Admin Exclusive Newsletter</a>
                    </div>
                    <div className="mobileNavOption">
                        <Link to="/promptlibrary" className="mobileNavLink">Prompt Library</Link>
                    </div>
                    <div className="mobileNavOption">
                        <button onClick={() => scrollTo("contactForm", 1)} className="mobileNavLink">Contact</button>
                    </div>
                </div>
            </div>
            <div className="topNav">
                <div className="navOption dropdown">
                    <div className="dropdown">
                        <button className="dropbtn">About Us</button>
                        <div className="dropdown-content">
                            <Link className='dropdownLink' to="/about">About</Link>
                            <Link className='dropdownLink' to="/testimonials">Training Testimonials</Link>
                        </div>
                    </div>
                </div>
                <div className="navOption dropdown">
                    <div className="dropdown">
                        <button className="dropbtn">Training</button>
                        <div className="dropdown-content">
                            <Link className='dropdownLink' to="/training">Training Portal</Link>
                            <Link className='dropdownLink' to="/traininginfo">Explore Training Opportunities</Link>
                        </div>
                    </div>
                </div>
                <div className="navOption dropdown">
                    <div className="dropdown">
                        <button className="dropbtn">Free Resources</button>
                        <div className="dropdown-content">
                            <Link className='dropdownLink' to="/promptlibrary">Prompt Library</Link>
                            <Link className='dropdownLink' to="/aitools">AI Tools Library</Link>
                            <a className='dropdownLink' href="https://direct-ai-updates-for-school-admins.beehiiv.com/subscribe?ref=GOT6dJ1dV5" target='_blank'>Admin Exclusive Newsletter</a>
                        </div>
                    </div>
                </div>
                <div className="navOption">
                    <div className="dropdown">
                        <button className="dropbtn">Contact</button>
                        <div className="dropdown-content">
                            <button className='dropdownLink' onClick={() => scrollTo("contactForm", 0)}>Contact Form</button>
                            <a className='dropdownLink' href="https://calendly.com/williamgrube/gruvy-education-meeting" target="_blank" rel="noopener noreferrer">Schedule Meeting</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container nav-content">
                <div className="brand-and-toggler">
                    <Link to="/" className="navbar-brand">
                        <img src={Logo} id="mainLogo" className="logo" alt="site logo" />
                    </Link>
                </div>
            </div>
        </nav>
    );
}

export default Nav1;
