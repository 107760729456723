import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import emailjs from "emailjs-com"
import { useNavigate } from 'react-router-dom';
import Nav from './Nav'
import Diffit from '../images/diffit.png'
import Auto from '../images/auto.jpg'
import ChatGPT from "../images/chatGPTicon.png"
import GPTZero from "../images/GPTZero.png"
import Eduaide from '../images/eduaide.jpg'
import { Link as ScrollLink } from 'react-scroll';
import ScrollToTop from './ScrollToTop';
import Footer from './Footer';
import YouTube from "../images/youtube-icon.png"
import X from '../images/X.png'
import Magic from "../images/magic.jpg"
import LinkedIn from '../images/LinkedIn.png'
import Facebook from '../images/Facebook.png'
import Library from '../images/NewLibrary.png'
import Tools from '../images/toolsIcon.png'
import Explore from '../images/Explore.png'
import Form from "../images/form.png"
import Schedule from '../images/Schedule.jpg'
import SchoolAI from '../images/SchoolAI.jpg'
import Canva from '../images/Canva.png'
import Snorkl from '../images/Snorkl.png'
import Gamma from '../images/Gamma.png'
import Curipod from '../images/Curipod.png'
import GoogleTranslate from '../images/GoogleTranslate.png'
import Academy from '../images/Academy.png'
const Landing = () => {

    const navigate = useNavigate();
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm("service_1g4hajp", "template_fjby2xf", e.target, "user_HAMTDSUiL200UN9RQyPHI")
        document.getElementById("success").style.display = "flex";
        document.getElementById("contactForm").style.display = "none";
    }

    const scrollToBottom = () => {
        // window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        const element = document.getElementById("contactForm");
        //you can do it by jquery. no matter
        element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    function toAbout() {
        var newTab = window.open('https://gruvyeducation.com/about', '_blank');
        newTab.focus();
    }

    return (

        <body>
            <div className="holder">
                <Nav />
                <header className="header-blue shortHeader">
                    <div className="container header-content noBottomMargin">
                        <div className="header-text text-white text-center">
                        </div>
                    </div>
                </header>
                <ScrollToTop />

                <section className="section-one bg-ghost-white lessPaddingTop">
                    <div className="directions ">
                        <p>Welcome to the presentation page! Check out the content below that we'll cover.</p>
                    </div>
                    <div className="title noMarginBottom">Teaching During The Rise of AI Presentation</div>
                    <div className="course-container">
                        <div className="course-navbar">
                            <ScrollLink activeClass="active" to="AITools" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Tool">🛠️</span> AI Tools</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="Library" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Book">📚</span> Libraries</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="AILinks" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Globe">🌐</span> Socials</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="NextSteps" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Check Mark">✅</span> Next Steps</div>
                            </ScrollLink>
                            <ScrollLink activeClass="active" to="contact" spy={true} smooth={true} offset={-70} duration={50} className="course-nav-item">
                                <div><span role="img" aria-label="Contact">📱</span> Contact</div>
                            </ScrollLink>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <section name='AITools' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🛠️ Free AI Tools</h2>
                            <div className="tool-grid">
                                <a href="https://chat.openai.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={ChatGPT} alt="ChatGPT" />
                                    </div>
                                    <h3 className="tool-title">ChatGPT</h3>
                                    <p className="tool-text">ChatGPT direct link.</p>
                                </a>
                                <a href="https://www.magicschool.ai/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Magic} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">Magic School</h3>
                                    <p className="tool-text">Your all-in-one AI toolkit for educators.</p>
                                </a>
                                <a href="https://schoolai.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={SchoolAI} alt="Magic School" />
                                    </div>
                                    <h3 className="tool-title">SchoolAI</h3>
                                    <p className="tool-text">Designed to bring AI into the classroom with built-in monitoring & controls for teachers.</p>
                                </a>
                                <a href="https://web.diffit.me/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Diffit} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Diffit</h3>
                                    <p className="tool-text">Creates AI-Generated Differentiated Student Activities.</p>
                                </a>
                                <a href="https://canva.me/ai-image-generator-en-gs" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Canva} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Canva Image Generator</h3>
                                    <p className="tool-text">Creates AI Generated Images.</p>
                                </a>
                                <a href="https://www.eduaide.ai/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Eduaide} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Eduaide.Ai</h3>
                                    <p className="tool-text">Most Wallet Friendly Paid AI Platform for Educators: Use the code "Gruvy" at checkout for an additional 20% off.</p>
                                </a>
                                <a href="https://snorkl.app/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Snorkl} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Snorkl</h3>
                                    <p className="tool-text">Voice-enabled Assignments Powered by AI.</p>
                                </a>
                                <a href="https://gamma.app/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Gamma} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Gamma</h3>
                                    <p className="tool-text">Your AI Powered Presentation Generator.</p>
                                </a>
                                <a href="https://curipod.com/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Curipod} alt="Diffit" />
                                    </div>
                                    <h3 className="tool-title">Curipod</h3>
                                    <p className="tool-text">Your AI Powered Interactive Lesson Generator.</p>
                                </a>
                                <a href="https://gptzero.me/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={GPTZero} alt="GPTZero" />
                                    </div>
                                    <h3 className="tool-title">GPTZero</h3>
                                    <p className="tool-text">AI Detection Tool.</p>
                                </a>
                            </div>
                        </section>
                        <section name='Library' className="course-section course-resources-section">
                            <h2 className="course-sub-title">📚 Libraries</h2>
                            <div className="tool-grid">
                                <a href="https://gruvyeducation.com/promptlibrary" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Library} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">ChatGPT Prompt Library</h3>
                                    <p className="tool-text">Collection of useful ChatGPT prompts for educators.</p>
                                </a>
                                <a href="https://gruvyeducation.com/aitools" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Tools} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Educator AI Tools Library</h3>
                                    <p className="tool-text">Collection of useful AI tools for educators</p>
                                </a>

                            </div>
                        </section>
                        <section name='AILinks' className="course-section course-resources-section">
                            <h2 className="course-sub-title">🌐 Socials</h2>
                            <div className="tool-grid">
                                <a href="https://twitter.com/Gruvy_edu" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={X} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">X</h3>
                                </a>
                                <a href="https://www.youtube.com/@GruvyEducation/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={YouTube} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">YouTube</h3>
                                </a>
                                <a href="https://www.linkedin.com/in/william-grube-16200a177/" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={LinkedIn} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">LinkedIn</h3>
                                </a>
                            </div>
                        </section>
                        <section name='NextSteps' className="course-section course-resources-section">
                            <h2 className="course-sub-title">✅ Next Steps</h2>
                            <div className="tool-grid">
                                <a href="https://youtu.be/IHeRRHIuJ3o" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={YouTube} alt="Youtube" />
                                    </div>
                                    <h3 className="tool-title">Learn about Gruvy Ecucation's AI Training Opportunities for K-12 Educators</h3>
                                    <p className="tool-text">Less than 5 minute explanation video.</p>
                                </a>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_2SwagH33-OQEOTcdQJv4Me0_IIiyAPYqF6NyHY0IYYvSTQ/viewform?usp=sf_link" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Form} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Bring Gruvy Education's AI Training to Your Educators</h3>
                                    <p className="tool-text">Join the 70+ schools participating in our AI in Education Training.</p>
                                </a>
                                {/* <a href="https://calendly.com/williamgrube/meet-william-at-ndcel" target="_blank" className="tool-card">
                                    <div className="tool-icon">
                                        <img src={Schedule} alt="Library" />
                                    </div>
                                    <h3 className="tool-title">Schedule a time to come see me today.</h3>
                                    <p className="tool-text">I will be set up in Hall B.</p>
                                </a> */}
                            </div>
                        </section>
                    </div>
                </section>
                <Footer />
            </div>
        </body >
    );
}

export default Landing;
